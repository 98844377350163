/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Category',
    type: 'CategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'appliances', label: 'Appliances' },
        { key: 'baby_gear', label: 'Baby Gear' },
        { key: 'bike_and_ebikes', label: 'Bikes & E-bikes' },
        { key: 'business_and_office', label: 'Business & Office' },
        { key: 'camping_and_hiking', label: 'Camping & Hiking' },
        { key: 'clothing_fashion', label: 'Clothing & Fashion' },
        { key: 'electric', label: 'Watercrafts & Charters' },
        { key: 'electronics', label: 'Electronics' },
        { key: 'fishing_gear_and_equipment', label: 'Fishing gear & Equipment' },
        { key: 'furniture_and_decorating', label: 'Furniture & Decorating' },
        { key: 'lighting', label: 'Lighting' },
        { key: 'music', label: 'Music' },
        { key: 'party_and_events', label: 'Party & Events' },
        { key: 'patio_and_backyard', label: 'Patio & Backyard' },
        { key: 'pet_gear', label: 'Pet Gear' },
        { key: 'photo_and_media', label: 'Photo & Media' },
        { key: 'sports', label: 'Sports' },
        { key: 'tools_and_equipment', label: 'Tools & Equipment' },
        { key: 'tours_and_adventures', label: 'Tours & Adventures' },
        { key: 'toys_and_games', label: 'Toys & Games' },
        { key: 'trailers_towing_hitches', label: 'Trailers, Towing & Hitches' },
        { key: 'travel', label: 'Travel' },
        { key: 'water_activities', label: 'Water Activities' },
      ],
    },
  },
  {
    id: 'subcategory',
    label: 'Subcategory',
    type: 'SubcategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        {
          key: 'powerboats_pontoons_and_ribs_w',
          label: 'Powerboats, Pontoons & RIBS',
          category: 'electric',
        },
        {
          key: 'jetskis_and_personal_watercrafts_w',
          label: 'Jetskis & Personal Watercrafts',
          category: 'electric',
        },
        { key: 'fishing_w', label: 'Fishing', category: 'electric' },
        { key: 'sailing_w', label: 'Sailing', category: 'electric' },
        {
          key: 'houseboats_and_sleepaboards_w',
          label: 'Houseboats & sleepaboards',
          category: 'electric',
        },
        { key: 'luxury_and_events_w', label: 'Luxury & Events', category: 'electric' },
        { key: 'unique_watercrafts_w', label: 'Unique Watercrafts', category: 'electric' },
        { key: 'e_bikes_b_a_e', label: 'E-bikes', category: 'bike_and_ebikes' },
        { key: 'bikes_b_a_e', label: 'Bikes', category: 'bike_and_ebikes' },
        { key: 'equipment_and_gear_b_a_e', label: 'Equipment & Gear', category: 'bike_and_ebikes' },
        { key: 'golf_s', label: 'Golf', category: 'sports' },
        { key: 'hockey_s', label: 'Hockey', category: 'sports' },
        { key: 'soccer_s', label: 'Soccer', category: 'sports' },
        { key: 'football_s', label: 'Football', category: 'sports' },
        { key: 'ringette_s', label: 'Ringette', category: 'sports' },
        { key: 'lacross_s', label: 'Lacrosse', category: 'sports' },
        { key: 'rugb_sy', label: 'Rugby', category: 'sports' },
        { key: 'frisbee_golf_s', label: 'Frisbee Golf', category: 'sports' },
        { key: 'racket_sports_s', label: 'Racket Sports', category: 'sports' },
        { key: 'basketball_s', label: 'Basketball', category: 'sports' },
        { key: 'baseball_s', label: 'Baseball', category: 'sports' },
        { key: 'ski_and_board_s', label: 'Ski & Board', category: 'sports' },
        { key: 'gym_equipment_s', label: 'Gym Equipment', category: 'sports' },
        { key: 'all_other_sports_s', label: 'All other sports', category: 'sports' },
        { key: 'paddle_sports_w_a', label: 'Paddle Sports', category: 'water_activities' },
        { key: 'watersports_w_a', label: 'Watersports', category: 'water_activities' },
        {
          key: 'scuba_and_snorkeling_w_a',
          label: 'Scuba & Snorkeling',
          category: 'water_activities',
        },
        {
          key: 'inflatables_and_trampolines_w_a',
          label: 'Inflatables & Trampolines',
          category: 'water_activities',
        },
        { key: 'games_and_toys_w_a', label: 'Games & Toys', category: 'water_activities' },
        { key: 'waterbikes_w_a', label: 'Waterbikes', category: 'water_activities' },
        {
          key: 'other_water_activities_w_a',
          label: 'Other water activities',
          category: 'water_activities',
        },
        { key: 'tents_p_a_e', label: 'Tents', category: 'party_and_events' },
        { key: 'inflatables_p_a_e', label: 'Inflatables', category: 'party_and_events' },
        { key: 'fog_machines_p_a_e', label: 'Fog machines', category: 'party_and_events' },
        {
          key: 'equipment_and_gear_p_a_e',
          label: 'Equipment & Gear',
          category: 'party_and_events',
        },
        { key: 'av_equipment_p_a_e', label: 'AV Equipment', category: 'party_and_events' },
        { key: 'tablets_and_chairs_p_a_e', label: 'Tables & Chairs', category: 'party_and_events' },
        { key: 'dishware_p_a_e', label: 'Dishware', category: 'party_and_events' },
        { key: 'linen_p_a_e', label: 'Linen', category: 'party_and_events' },
        { key: 'decor_p_a_e', label: 'Decor', category: 'party_and_events' },
        { key: 'staging_p_a_e', label: 'Staging', category: 'party_and_events' },
        { key: 'restrooms_p_a_e', label: 'Restrooms', category: 'party_and_events' },
        { key: 'dj_p_a_e', label: 'DJ', category: 'party_and_events' },
        { key: 'photobooth_p_a_e', label: 'Photobooth', category: 'party_and_events' },
        { key: 'tradeshow_p_a_e', label: 'Tradeshow', category: 'party_and_events' },
        { key: 'other_p_a_e', label: 'Other', category: 'party_and_events' },
        { key: 'rods_f_g_a_e', label: 'Rods', category: 'fishing_gear_and_equipment' },
        {
          key: 'gear_and_equipment_f_g_a_e',
          label: 'Gear & Equipment',
          category: 'fishing_gear_and_equipment',
        },
        { key: 'camping_tents_c_a_h', label: 'Camping tents', category: 'camping_and_hiking' },
        { key: 'outdoor_cooking_c_a_h', label: 'Outdoor Cooking', category: 'camping_and_hiking' },
        { key: 'packs_and_bags_c_a_h', label: 'Packs and bags', category: 'camping_and_hiking' },
        { key: 'sleeping_c_a_h', label: 'Sleeping', category: 'camping_and_hiking' },
        { key: 'hiking_c_a_h', label: 'Hiking', category: 'camping_and_hiking' },
        { key: 'navigation_c_a_h', label: 'Navigation', category: 'camping_and_hiking' },
        { key: 'safety_c_a_h', label: 'Safety', category: 'camping_and_hiking' },
        { key: 'accessories_c_a_h', label: 'Accessories', category: 'camping_and_hiking' },
        {
          key: 'storage_and_coolers_c_a_h',
          label: 'Storage and coolers',
          category: 'camping_and_hiking',
        },
        { key: 'seating_c_a_h', label: 'Seating', category: 'camping_and_hiking' },
        { key: 'other_c_a_h', label: 'Other', category: 'camping_and_hiking' },
        { key: 'luggage_t', label: 'Luggage', category: 'travel' },
        { key: 'accessories_t', label: 'Accessories', category: 'travel' },
        { key: 'cameras_p_a_m', label: 'Cameras', category: 'photo_and_media' },
        { key: 'video cameras_p_a_m', label: 'Video cameras', category: 'photo_and_media' },
        { key: 'lenses_p_a_m', label: 'Lenses', category: 'photo_and_media' },
        { key: 'gear_and_equipment_p_a_m', label: 'Gear & Equipment', category: 'photo_and_media' },
        { key: 'ac_fans_and_heating_a', label: 'A/C, Fans & Heating', category: 'appliances' },
        { key: 'kitchen_a', label: 'Kitchen', category: 'appliances' },
        { key: 'laundry_a', label: 'Laundry', category: 'appliances' },
        { key: 'cleaning_a', label: 'Cleaning', category: 'appliances' },
        {
          key: 'other_household_appliances_a',
          label: 'Other household appliances',
          category: 'appliances',
        },
        { key: 'hand_tools_t_a_e', label: 'Hand tools', category: 'tools_and_equipment' },
        { key: 'power_tools_t_a_e', label: 'Power tools', category: 'tools_and_equipment' },
        {
          key: 'garden_and_yardcare_t_a_e',
          label: 'Garden & yardcare',
          category: 'tools_and_equipment',
        },
        { key: 'automotive_t_a_e', label: 'Automotive', category: 'tools_and_equipment' },
        {
          key: 'air_tools_and_compressors_t_a_e',
          label: 'Air tools & Compressors',
          category: 'tools_and_equipment',
        },
        {
          key: 'ladders_and_scaffolding_t_a_e',
          label: 'Ladders & Scaffolding',
          category: 'tools_and_equipment',
        },
        {
          key: 'fencing_and_security_t_a_e',
          label: 'Fencing & Security',
          category: 'tools_and_equipment',
        },
        { key: 'safety_t_a_e', label: 'Safety', category: 'tools_and_equipment' },
        { key: 'electronics_t_a_e', label: 'Electronics', category: 'tools_and_equipment' },
        {
          key: 'accessories_and_attachments_t_a_e',
          label: 'Accessories & Attachments',
          category: 'tools_and_equipment',
        },
        { key: 'other_t_a_e', label: 'Other', category: 'tools_and_equipment' },
        { key: 'tours_and_adventures_t_a_a', label: 'Tours & Adventures', category: 'tours_and_adventures' },
        { key: 'racks_t_t_h', label: 'Racks', category: 'trailers_towing_hitches' },
        { key: 'hitches_t_t_h', label: 'Hitches', category: 'trailers_towing_hitches' },
        { key: 'trailers_t_t_h', label: 'Trailers', category: 'trailers_towing_hitches' },
        {
          key: 'other_towables_and_attachments_t_t_h',
          label: 'Other towables & attachments',
          category: 'trailers_towing_hitches',
        },
        { key: 'instruments_m', label: 'Instruments', category: 'music' },
        { key: 'gear_and_equipment_m', label: 'Gear & Equipment', category: 'music' },
        {
          key: 'car_seats_and_equipment_b_g',
          label: 'Car seats & equipment',
          category: 'baby_gear',
        },
        { key: 'strollers_b_g', label: 'Strollers', category: 'baby_gear' },
        { key: 'cribs_and_cots_b_g', label: 'Cribs & Cots', category: 'baby_gear' },
        { key: 'safety_b_g', label: 'Safety', category: 'baby_gear' },
        { key: 'toys_and_games_b_g', label: 'Toys & Games', category: 'baby_gear' },
        { key: 'clothing_b_g', label: 'Clothing', category: 'baby_gear' },
        { key: 'other_baby_gear_b_g', label: 'Other baby gear', category: 'baby_gear' },
        {
          key: 'business_and_office_b_a_o',
          label: 'Business & Office',
          category: 'business_and_office',
        },
        { key: 'industrial_and_event_l', label: 'Industrial & Event', category: 'lighting' },
        { key: 'home_and_work_l', label: 'Home & Work', category: 'lighting' },
        { key: 'male_c_f', label: 'Male', category: 'clothing_fashion' },
        { key: 'female_c_f', label: 'Female', category: 'clothing_fashion' },
        { key: 'unisex_c_f', label: 'Unisex', category: 'clothing_fashion' },
        { key: 'jewelry_c_f', label: 'Jewelry', category: 'clothing_fashion' },
        { key: 'costumes_c_f', label: 'Costumes', category: 'clothing_fashion' },
        { key: 'computing_e', label: 'Computing', category: 'electronics' },
        { key: 'gaming_e', label: 'Gaming', category: 'electronics' },
        {
          key: 'headphones_speakers_audio_speakers_audioy_e',
          label: 'Headphones, Speakers, Audio',
          category: 'electronics',
        },
        { key: 'tv_and_home_theatre_e', label: 'TV & Home theatre', category: 'electronics' },
        { key: 'printers_e', label: 'Printers', category: 'electronics' },
        { key: 'wearable_tech_e', label: 'Wearable Tech.', category: 'electronics' },
        { key: 'cellphones_and_tablets_e', label: 'Cellphones & Tablets', category: 'electronics' },
        { key: 'virtual_reality_e', label: 'Virtual Reality', category: 'electronics' },
        { key: 'drones', label: 'Drones', category: 'electronics' },
        { key: 'rc_s_e', label: 'RC’s', category: 'electronics' },
        { key: 'other_e', label: 'Other', category: 'electronics' },
        { key: 'furniture_f_a_d', label: 'Furniture', category: 'furniture_and_decorating' },
        {
          key: 'interior_decorating_f_a_d',
          label: 'Interior decorating',
          category: 'furniture_and_decorating',
        },
        {
          key: 'holiday_decorations_f_a_d',
          label: 'Holiday decorations',
          category: 'furniture_and_decorating',
        },
        { key: 'other_f_a_d', label: 'Other', category: 'furniture_and_decorating' },
        { key: 'board_games_t_a_g', label: 'Board games', category: 'toys_and_games' },
        { key: 'toys_games_t_a_g', label: 'Toys', category: 'toys_and_games' },
        { key: 'party_games_t_a_g', label: 'Party games', category: 'toys_and_games' },
        { key: 'outdoor_games_t_a_g', label: 'Outdoor games', category: 'toys_and_games' },
        { key: 'other_games_t_a_g', label: 'All other games', category: 'toys_and_games' },
        { key: 'bbqs_and_cooking_p_a_b', label: 'BBQ’s & Cooking', category: 'patio_and_backyard' },
        { key: 'trampolines_p_a_b', label: 'Trampolines', category: 'patio_and_backyard' },
        { key: 'patio_furniture_p_a_b', label: 'Patio Furniture', category: 'patio_and_backyard' },
        { key: 'outdoor_heating_p_a_b', label: 'Outdoor Heating', category: 'patio_and_backyard' },
        {
          key: 'accessories_and_qquipment_p_a_b',
          label: 'Accessories & Equipment',
          category: 'patio_and_backyard',
        },
        {
          key: 'hot_tubs_and_pools_p_a_b',
          label: 'Hot tubs & Pools',
          category: 'patio_and_backyard',
        },
        {
          key: 'other_patio_and_backyard_p_a_b',
          label: 'Other Patio & Backyard',
          category: 'patio_and_backyard',
        },
        { key: 'cages_and_crates_p_g', label: 'Cages & Crates', category: 'pet_gear' },
        { key: 'travel_p_g', label: 'Travel', category: 'pet_gear' },
        { key: 'beds_p_g', label: 'Beds', category: 'pet_gear' },
        { key: 'equipment_and_gear_p_g', label: 'Equipment & Gear', category: 'pet_gear' },
        { key: 'other_p_g', label: 'Other', category: 'pet_gear' },
      ],
    },
  },
  {
    id: 'region',
    label: 'Region',
    type: 'RegionFilter',
    group: 'primary',
    queryParamNames: ['pub_region'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'alberta', label: 'Alberta' },
        { key: 'british_columbia', label: 'British Columbia' },
        { key: 'manitoba', label: 'Manitoba' },
        { key: 'ontario', label: 'Ontario' },
        { key: 'saskatchewan', label: 'Saskatchewan' },
      ],
    },
  },
  {
    id: 'rental_term',
    label: 'Rental term',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_keywordPricing'],
    config: {
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'per_hour', label: 'Per hour' },
        { key: 'per_day', label: 'Per day' },
        { key: 'per_week', label: 'Per week' },
        { key: 'per_month', label: 'Per month' },
      ],
    },
  },
  {
    id: 'amount',
    label: 'How many would you like to rent?',
    type: 'Filter',
    group: 'secondary',
    queryParamNames: ['pub_amount'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6' },
        { key: '7', label: '7' },
        { key: '8', label: '8' },
        { key: '9', label: '9' },
        { key: '10', label: '10' },
        { key: '11', label: '11' },
        { key: '12', label: '12' },
      ],
    },
  },
  {
    id: 'term',
    label: 'Rental Term',
    type: 'Filter',
    group: 'secondary',
    queryParamNames: ['pub_term'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: '1 hour' },
        { key: '2', label: '2 hours' },
        { key: '3', label: '3 hours' },
        { key: '4', label: '4 hours' },
        { key: '5', label: '5 hours' },
        { key: '6', label: '6 hours' },
        { key: '7', label: '7 hours' },
        { key: '8', label: '8 hours' },
        { key: '9', label: '9 hours' },
        { key: '10', label: '10 hours' },
        { key: '11', label: '11 hours' },
        { key: '12', label: '12 hours' },
        { key: '13', label: '13 hours' },
        { key: '14', label: '14 hours' },
        { key: '15', label: '15 hours' },
        { key: '16', label: '16 hours' },
        { key: '17', label: '17 hours' },
        { key: '18', label: '18 hours' },
        { key: '19', label: '19 hours' },
        { key: '20', label: '20 hours' },
        { key: '21', label: '21 hours' },
        { key: '22', label: '22 hours' },
        { key: '23', label: '23 hours' },
        { key: '24', label: '24 hours' },
      ],
    },
  },
  {
    id: 'time',
    label: 'Rental Start Time',
    type: 'Filter',
    group: 'secondary',
    queryParamNames: ['pub_time'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '12:00', label: '12:00' },
        { key: '12:15', label: '12:15' },
        { key: '12:30', label: '12:30' },
        { key: '12:45', label: '12:45' },
        { key: '1:00', label: '1:00' },
        { key: '1:15', label: '1:15' },
        { key: '1:30', label: '1:30' },
        { key: '1:45', label: '1:45' },
        { key: '2:00', label: '2:00' },
        { key: '2:15', label: '2:15' },
        { key: '2:30', label: '2:30' },
        { key: '2:45', label: '2:45' },
        { key: '3:00', label: '3:00' },
        { key: '3:15', label: '3:15' },
        { key: '3:30', label: '3:30' },
        { key: '3:45', label: '3:45' },
        { key: '4:00', label: '4:00' },
        { key: '4:15', label: '4:15' },
        { key: '4:30', label: '4:30' },
        { key: '4:45', label: '4:45' },
        { key: '5:00', label: '5:00' },
        { key: '5:15', label: '5:15' },
        { key: '5:30', label: '5:30' },
        { key: '5:45', label: '5:45' },
        { key: '6:00', label: '6:00' },
        { key: '6:15', label: '6:15' },
        { key: '6:30', label: '6:30' },
        { key: '6:45', label: '6:45' },
        { key: '7:00', label: '7:00' },
        { key: '7:15', label: '7:15' },
        { key: '7:30', label: '7:30' },
        { key: '7:45', label: '7:45' },
        { key: '8:00', label: '8:00' },
        { key: '8:15', label: '8:15' },
        { key: '8:30', label: '8:30' },
        { key: '8:45', label: '8:45' },
        { key: '9:00', label: '9:00' },
        { key: '9:15', label: '9:15' },
        { key: '9:30', label: '9:30' },
        { key: '9:45', label: '9:45' },
        { key: '10:00', label: '10:00' },
        { key: '10:15', label: '10:15' },
        { key: '10:30', label: '10:30' },
        { key: '10:45', label: '10:45' },
        { key: '11:00', label: '11:00' },
        { key: '11:15', label: '11:15' },
        { key: '11:30', label: '11:30' },
        { key: '11:45', label: '11:45' },
      ],
    },
  },
  {
    id: 'period',
    label: 'Rental Period',
    type: 'Filter',
    group: 'secondary',
    queryParamNames: ['pub_period'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [{ key: 'am', label: 'am' }, { key: 'pm', label: 'pm' }],
    },
  },
  {
    id: 'method',
    label: 'Method to pay the damage deposit',
    type: 'Filter',
    group: 'secondary',
    queryParamNames: ['pub_method'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'cash', label: 'Cash' },
        { key: 'card', label: 'Credit or debit card' },
        { key: 'mobile_payment', label: 'Mobile payment' },
        { key: 'electonic_bank_transfer', label: 'Electronic bank transfer' },
      ],
    },
  },
  {
    id: 'amenities',
    label: 'Amenities',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'essentials_vp',
          label: 'Essentials',
          category: 'vacation_properties',
        },
        {
          key: 'pets_allowed_vp',
          label: 'Pets Allowed',
          category: 'vacation_properties',
        },
        {
          key: 'internet_vp',
          label: 'Internet',
          category: 'vacation_properties',
        },
        {
          key: 'wireless_internet_vp',
          label: 'Wireless Internet',
          category: 'vacation_properties',
        },
        {
          key: 'breakfast_included_vp',
          label: 'Breakfast Included',
          category: 'vacation_properties',
        },
        {
          key: 'free_parking_on_property_vp',
          label: 'Free Parking on Property',
          category: 'vacation_properties',
        },
        {
          key: 'barbeque_vp',
          label: 'Barbeque',
          category: 'vacation_properties',
        },
        {
          key: 'hot_tub_vp',
          label: 'Hot Tub',
          category: 'vacation_properties',
        },
        {
          key: 'wheelchair_accessible_vp',
          label: 'Wheelchair Accessible',
          category: 'vacation_properties',
        },
        {
          key: 'kitchen_vp',
          label: 'Kitchen',
          category: 'vacation_properties',
        },
        {
          key: 'cable_tv_vp',
          label: 'Cable TV',
          category: 'vacation_properties',
        },
        {
          key: 'iron_vp',
          label: 'Iron',
          category: 'vacation_properties',
        },
        {
          key: 'hair_dryer_vp',
          label: 'Hair Dryer',
          category: 'vacation_properties',
        },
        {
          key: 'gym_vp',
          label: 'Gym',
          category: 'vacation_properties',
        },
        {
          key: 'smoking_allowed_vp',
          label: 'Smoking Allowed',
          category: 'vacation_properties',
        },
        {
          key: '24_hour_check_in_vp',
          label: '24 hour check-in',
          category: 'vacation_properties',
        },
        {
          key: 'doorman_vp',
          label: 'Doorman',
          category: 'vacation_properties',
        },
        {
          key: 'concierge_vp',
          label: 'Concierge',
          category: 'vacation_properties',
        },
        {
          key: 'pool_vp',
          label: 'Pool',
          category: 'vacation_properties',
        },
        {
          key: 'private_bathroom_vp',
          label: 'Private Bathroom',
          category: 'vacation_properties',
        },
        {
          key: 'in_suite_washer_vp',
          label: 'In-suite Washer',
          category: 'vacation_properties',
        },
        {
          key: 'in_suite_laundry_vp',
          label: 'In-suite Laundry',
          category: 'vacation_properties',
        },
        {
          key: 'dishwasher_vp',
          label: 'Dishwasher',
          category: 'vacation_properties',
        },
        {
          key: 'heating_vp',
          label: 'Heating',
          category: 'vacation_properties',
        },
        {
          key: 'a_c_vp',
          label: 'A/C',
          category: 'vacation_properties',
        },
        {
          key: 'coffee_machine_vp',
          label: 'Coffee Machine',
          category: 'vacation_properties',
        },
        {
          key: 'tv_vp',
          label: 'TV',
          category: 'vacation_properties',
        },
        {
          key: 'private_living_room_vp',
          label: 'Private Living Room',
          category: 'vacation_properties',
        },
        {
          key: 'private_entrance_vp',
          label: 'Private Entrance',
          category: 'vacation_properties',
        },
        {
          key: 'free_parking_on_street_vp',
          label: 'Free Parking on Street',
          category: 'vacation_properties',
        },
        {
          key: 'computer_vp',
          label: 'Computer',
          category: 'vacation_properties',
        },
        {
          key: 'printer_vp',
          label: 'Printer',
          category: 'vacation_properties',
        },
        {
          key: 'security_alarm_vp',
          label: 'Security Alarm',
          category: 'vacation_properties',
        },
        {
          key: 'smoke_detector_vp',
          label: 'Smoke Detector',
          category: 'vacation_properties',
        },
        {
          key: 'indoor_fireplace_vp',
          label: 'Indoor Fireplace',
          category: 'vacation_properties',
        },
        {
          key: 'wheelchair_accessible_v',
          label: 'Wheelchair Accessible',
          category: 'vehicles',
        },
        {
          key: 'all_wheel_drive_v',
          label: 'All-Wheel Drive',
          category: 'vehicles',
        },
        {
          key: 'apple_carplay_v',
          label: 'Apple Carplay',
          category: 'vehicles',
        },
        {
          key: 'android_auto_v',
          label: 'Android Auto',
          category: 'vehicles',
        },
        {
          key: 'aux_input_v',
          label: 'Aux Input',
          category: 'vehicles',
        },
        {
          key: 'backup_camera_v',
          label: 'Backup Camera',
          category: 'vehicles',
        },
        {
          key: 'blind_spot_warning_v',
          label: 'Blind Spot Warning',
          category: 'vehicles',
        },
        {
          key: 'bike_rack_v',
          label: 'Bike Rack',
          category: 'vehicles',
        },
        {
          key: 'snow_tires_and_chains_v',
          label: 'Snow Tires & Chains',
          category: 'vehicles',
        },
        {
          key: 'sun_roof_v',
          label: 'Sun Roof',
          category: 'vehicles',
        },
        {
          key: 'automatic_dimming_rear_view_mirror_v',
          label: 'Automatic-dimming Rear-view Mirror',
          category: 'vehicles',
        },
        {
          key: 'automatic_headlight_control_v',
          label: 'Automatic Headlight Control',
          category: 'vehicles',
        },
        {
          key: 'sliding_sun_visors_v',
          label: 'Sliding Sun Visors',
          category: 'vehicles',
        },
        {
          key: 'memory_mirrors_v',
          label: 'Memory Mirrors',
          category: 'vehicles',
        },
        {
          key: 'memory_seats_v',
          label: 'Memory Seats',
          category: 'vehicles',
        },
        {
          key: 'toll_pass_v',
          label: 'Toll Pass',
          category: 'vehicles',
        },
        {
          key: 'privacy_glass_v',
          label: 'Privacy Glass',
          category: 'vehicles',
        },
        {
          key: 'rain_sensing_windshield_wipers_v',
          label: 'Rain-sensing Wipers',
          category: 'vehicles',
        },
        {
          key: 'hybrid_v',
          label: 'Hybrid',
          category: 'vehicles',
        },
        {
          key: 'bluetooth_v',
          label: 'Bluetooth',
          category: 'vehicles',
        },
        {
          key: 'child_seat_v',
          label: 'Child Seat',
          category: 'vehicles',
        },
        {
          key: 'convertible_v',
          label: 'Convertible',
          category: 'vehicles',
        },
        {
          key: 'gps_v',
          label: 'GPS',
          category: 'vehicles',
        },
        {
          key: 'cruise_control_v',
          label: 'Cruise Control',
          category: 'vehicles',
        },
        {
          key: 'dynamic_cruise_control_v',
          label: 'Dynamic Cruise Control',
          category: 'vehicles',
        },
        {
          key: 'heated_seats_v',
          label: 'Heated Seats',
          category: 'vehicles',
        },
        {
          key: 'heated_steering_wheel_v',
          label: 'Heated Steering Wheel',
          category: 'vehicles',
        },
        {
          key: 'keyless_entry_v',
          label: 'Keyless Entry',
          category: 'vehicles',
        },
        {
          key: 'hands_free_trunk_lid_opening_v',
          label: 'Hands-free Trunk-lid Opening',
          category: 'vehicles',
        },
        {
          key: 'pet_friendly_v',
          label: 'Pet Friendly',
          category: 'vehicles',
        },
        {
          key: 'ski_rack_v',
          label: 'Ski Rack',
          category: 'vehicles',
        },
        {
          key: 'usb_charger_v',
          label: 'USB Charger',
          category: 'vehicles',
        },
        {
          key: 'usb_input_v',
          label: 'USB Input',
          category: 'vehicles',
        },
        {
          key: 'electric_v',
          label: 'Electric',
          category: 'vehicles',
        },
        {
          key: 'a_c_v',
          label: 'A/C',
          category: 'vehicles',
        },
        {
          key: 'multiple_zone_climate_control_v',
          label: 'Multiple Zone Climate Control',
          category: 'vehicles',
        },
        {
          key: 'wheelchair_accessible_rvs_a_c',
          label: 'Wheelchair Accessible',
          category: 'rvs_and_campers',
        },
        {
          key: 'all_wheel_drive_rvs_a_c',
          label: 'All-Wheel Drive',
          category: 'rvs_and_campers',
        },
        {
          key: 'apple_carplay_rvs_a_c',
          label: 'Apple Carplay',
          category: 'rvs_and_campers',
        },
        {
          key: 'android_auto_rvs_a_c',
          label: 'Android Auto',
          category: 'rvs_and_campers',
        },
        {
          key: 'aux_input_rvs_a_c',
          label: 'Aux Input',
          category: 'rvs_and_campers',
        },
        {
          key: 'backup_camera_rvs_a_c',
          label: 'Backup Camera',
          category: 'rvs_and_campers',
        },
        {
          key: 'blind_spot_warning_rvs_a_c',
          label: 'Blind Spot Warning',
          category: 'rvs_and_campers',
        },
        {
          key: 'bike_rack_rvs_a_c',
          label: 'Bike Rack',
          category: 'rvs_and_campers',
        },
        {
          key: 'snow_tires_and_chains_rvs_a_c',
          label: 'Snow Tires & Chains',
          category: 'rvs_and_campers',
        },
        {
          key: 'sun_roof_rvs_a_c',
          label: 'Sun Roof',
          category: 'rvs_and_campers',
        },
        {
          key: 'toll_pass_rvs_a_c',
          label: 'Toll Pass',
          category: 'rvs_and_campers',
        },
        {
          key: 'hybrid_rvs_a_c',
          label: 'Hybrid',
          category: 'rvs_and_campers',
        },
        {
          key: 'tow_hitch_rvs_a_c',
          label: 'Tow Hitch',
          category: 'rvs_and_campers',
        },
        {
          key: 'extra_storage_rvs_a_c',
          label: 'Extra Storage',
          category: 'rvs_and_campers',
        },
        {
          key: 'tv_and_dvd_rvs_a_c',
          label: 'TV & DVD',
          category: 'rvs_and_campers',
        },
        {
          key: 'oven_rvs_a_c',
          label: 'Oven',
          category: 'rvs_and_campers',
        },
        {
          key: 'heater_rvs_a_c',
          label: 'Heater',
          category: 'rvs_and_campers',
        },
        {
          key: 'linens_provided_rvs_a_c',
          label: 'linens_provided',
          category: 'rvs_and_campers',
        },
        {
          key: 'dining_table_rvs_a_c',
          label: 'Dining Table',
          category: 'rvs_and_campers',
        },
        {
          key: 'radio_rvs_a_c',
          label: 'Radio',
          category: 'rvs_and_campers',
        },
        {
          key: 'microwave_rvs_a_c',
          label: 'Microwave',
          category: 'rvs_and_campers',
        },
        {
          key: 'cd_player_rvs_a_c',
          label: 'CD Player',
          category: 'rvs_and_campers',
        },
        {
          key: 'stove_rvs_a_c',
          label: 'Stove',
          category: 'rvs_and_campers',
        },
        {
          key: 'range_rvs_a_c',
          label: 'Range',
          category: 'rvs_and_campers',
        },
        {
          key: 'leveling_jacks_rvs_a_c',
          label: 'Leveling Jacks',
          category: 'rvs_and_campers',
        },
        {
          key: 'bluetooth_rvs_a_c',
          label: 'Bluetooth',
          category: 'rvs_and_campers',
        },
        {
          key: 'child_seat_rvs_a_c',
          label: 'Child Seat',
          category: 'rvs_and_campers',
        },
        {
          key: 'convertible_rvs_a_c',
          label: 'Convertible',
          category: 'rvs_and_campers',
        },
        {
          key: 'gps_rvs_a_c',
          label: 'GPs',
          category: 'rvs_and_campers',
        },
        {
          key: 'heated_seats_rvs_a_c',
          label: 'Heated Seats',
          category: 'rvs_and_campers',
        },
        {
          key: 'keyless_entry_rvs_a_c',
          label: 'Keyless Entry',
          category: 'rvs_and_campers',
        },
        {
          key: 'pet_friendly_rvs_a_c',
          label: 'Pet Friendly',
          category: 'rvs_and_campers',
        },
        {
          key: 'ski_rack_rvs_a_c',
          label: 'Ski Rack',
          category: 'rvs_and_campers',
        },
        {
          key: 'usb_charger_rvs_a_c',
          label: 'USB Charger',
          category: 'rvs_and_campers',
        },
        {
          key: 'usb_input_rvs_a_c',
          label: 'USB Input',
          category: 'rvs_and_campers',
        },
        {
          key: 'electric_rvs_a_c',
          label: 'Electric',
          category: 'rvs_and_campers',
        },
        {
          key: 'a_c_rvs_a_c',
          label: 'A/C',
          category: 'rvs_and_campers',
        },
        {
          key: 'kitchen_sink_rvs_a_c',
          label: 'Kitchen Sink',
          category: 'rvs_and_campers',
        },
        {
          key: 'ceiling_fan_rvs_a_c',
          label: 'Ceiling Fan',
          category: 'rvs_and_campers',
        },
        {
          key: 'toilet_rvs_a_c',
          label: 'Toilet',
          category: 'rvs_and_campers',
        },
        {
          key: 'outside_shower_rvs_a_c',
          label: 'Outside Shower',
          category: 'rvs_and_campers',
        },
        {
          key: 'on_board_generator_rvs_a_c',
          label: 'On Board Generator',
          category: 'rvs_and_campers',
        },
        {
          key: 'full_winter_rental_available_rvs_a_c',
          label: 'Full-winter Rental Available',
          category: 'rvs_and_campers',
        },
        {
          key: 'inverter_rvs_a_c',
          label: 'Inverter',
          category: 'rvs_and_campers',
        },
        {
          key: 'audio_inputs_rvs_a_c',
          label: 'Audio Inputs',
          category: 'rvs_and_campers',
        },
        {
          key: 'refrigerator_rvs_a_c',
          label: 'Refrigerator',
          category: 'rvs_and_campers',
        },
        {
          key: 'inside_shower_rvs_a_c',
          label: 'Inside Shower',
          category: 'rvs_and_campers',
        },
        {
          key: 'camping_chairs_provided_rvs_a_c',
          label: 'Camping Chairs Provided',
          category: 'rvs_and_campers',
        },
        {
          key: 'family_friendly_rvs_a_c',
          label: 'Family Friendly',
          category: 'rvs_and_campers',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listingCategories = [
  {
    key: 'recreational',
    title: 'Recreational',
    subcategories: [
      {
        key: 'tours-and-adventures',
        title: 'Tours & Adventures',
        image: '/static/category-images/tours-and-adventures.jpg',
        link: '?pub_category=tours_and_adventures',
      },
      {
        key: 'watercrafts-and-charters',
        title: 'Watercrafts & Charters',
        image: '/static/category-images/watercrafts-and-charters.jpg',
        link: '?pub_category=electric',
      },
      {
        key: 'bikes-and-ebikes',
        title: 'Bikes & E-bikes',
        image: '/static/category-images/bikes-and-ebikes.jpg',
        link: '?pub_category=bike_and_ebikes',
      },
      {
        key: 'sports',
        title: 'Sports',
        image: '/static/category-images/sports.jpg',
        link: '?pub_category=sports',
      },
      {
        key: 'water-activities',
        title: 'Water Activities',
        image: '/static/category-images/water-activities.jpg',
        link: '?pub_category=water_activities',
      },
      {
        key: 'party-and-events',
        title: 'Party & Events',
        image: '/static/category-images/party-and-events.jpg',
        link: '?pub_category=party_and_events',
      },
      {
        key: 'fishing-gear-and-equipment',
        title: 'Fishing gear & equipment',
        image: '/static/category-images/fishing-gear-and-equipment.jpg',
        link: '?pub_category=fishing_gear_and_equipment',
      },
      {
        key: 'camping-and-hiking',
        title: 'Camping & Hiking',
        image: '/static/category-images/camping-and-hiking.jpg',
        link: '?pub_category=camping_and_hiking',
      },
      {
        key: 'travel',
        title: 'Travel',
        image: '/static/category-images/travel.jpg',
        link: '?pub_category=travel',
      },
    ],
  },
  {
    key: 'home-and-work',
    title: 'Home & Work',
    subcategories: [
      {
        key: 'photo-and-media',
        title: 'Photo & Media',
        image: '/static/category-images/photo-and-media.jpg',
        link: '?pub_category=photo_and_media',
      },
      {
        key: 'appliances',
        title: 'Appliances',
        image: '/static/category-images/appliances.jpg',
        link: '?pub_category=appliances',
      },
      {
        key: 'tools-and-equipment',
        title: 'Tools & Equipment',
        image: '/static/category-images/tools-and-equipment.jpg',
        link: '?pub_category=tools_and_equipment',
      },
      {
        key: 'trailers-towing-and-hitches',
        title: 'Trailers, Towing & Hitches',
        image: '/static/category-images/trailers-towing-and-hitches.jpg',
        link: '?pub_category=trailers_towing_hitches',
      },
      {
        key: 'music',
        title: 'Music',
        image: '/static/category-images/music.jpg',
        link: '?pub_category=music',
      },
      {
        key: 'baby-gear',
        title: 'Baby Gear',
        image: '/static/category-images/baby-gear.jpg',
        link: '?pub_category=baby_gear',
      },
      {
        key: 'business-and-office',
        title: 'Business & Office',
        image: '/static/category-images/business-and-office.jpg',
        link: '?pub_category=business_and_office',
      },
      {
        key: 'lighting',
        title: 'Lighting',
        image: '/static/category-images/lighting.jpg',
        link: '?pub_category=lighting',
      },
      {
        key: 'clothing-and-fashion',
        title: 'Clothing & Fashion',
        image: '/static/category-images/clothing-and-fashion.jpg',
        link: '?pub_category=clothing_fashion',
      },
      {
        key: 'electronics',
        title: 'Electronics',
        image: '/static/category-images/electronics.jpg',
        link: '?pub_category=electronics',
      },
      {
        key: 'furniture-and-decorating',
        title: 'Furniture & Decorating',
        image: '/static/category-images/furniture-and-decorating.jpg',
        link: '?pub_category=furniture_and_decorating',
      },
      {
        key: 'toys-and-games',
        title: 'Toys & Games',
        image: '/static/category-images/toys-and-games.jpg',
        link: '?pub_category=toys_and_games',
      },
      {
        key: 'patio-and-backyard',
        title: 'Patio & Backyard',
        image: '/static/category-images/patio-and-backyard.jpg',
        link: '?pub_category=patio_and_backyard',
      },
      {
        key: 'pet-gear',
        title: 'Pet Gear',
        image: '/static/category-images/pet-gear.jpg',
        link: '?pub_category=pet_gear',
      },
    ],
  },
];
