import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.css';

const MODAL_BREAKPOINT = 768;

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

  const hasImages = listing.images && listing.images.length > 0;
  const hasOneImage = hasImages && listing.images.length < 2;
  const hasTwoImages = hasImages && listing.images.length == 2;
  const hasThreeImages = hasImages && listing.images.length > 2;
  const firstImage = hasImages ? listing.images[0] : null;
  const secondImage = hasImages ? listing.images[1] : null;
  const thirdImage = hasImages ? listing.images[2] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={isMobileLayout ? css.sectionImages : css.sectionImagesHalf}>
      {actionBar}
      {isMobileLayout ? (
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            {actionBar}
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
            {viewPhotosButton}
          </div>
        </div>
      ) : (
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            {actionBar}
            {hasOneImage && (
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={[
                  'landscape-crop',
                  'landscape-crop2x',
                  'landscape-crop4x',
                  'landscape-crop6x',
                ]}
              />
            )}
            {hasTwoImages && (
              <>
                <ResponsiveImage
                  rootClassName={css.rootForImageHalf}
                  alt={title}
                  image={firstImage}
                  variants={[
                    'landscape-crop',
                    'landscape-crop2x',
                    'landscape-crop4x',
                    'landscape-crop6x',
                  ]}
                />
                <ResponsiveImage
                  rootClassName={css.rootForImagePiece}
                  alt={title}
                  image={secondImage}
                  variants={[
                    'landscape-crop',
                    'landscape-crop2x',
                    'landscape-crop4x',
                    'landscape-crop6x',
                  ]}
                />
              </>
            )}
            {hasThreeImages && (
              <>
                <ResponsiveImage
                  rootClassName={css.rootForImageHalf}
                  alt={title}
                  image={firstImage}
                  variants={[
                    'landscape-crop',
                    'landscape-crop2x',
                    'landscape-crop4x',
                    'landscape-crop6x',
                  ]}
                />
                <ResponsiveImage
                  rootClassName={css.rootForImagePart}
                  alt={title}
                  image={secondImage}
                  variants={[
                    'landscape-crop',
                    'landscape-crop2x',
                    'landscape-crop4x',
                    'landscape-crop6x',
                  ]}
                />
                <ResponsiveImage
                  rootClassName={css.rootForImageLeft}
                  alt={title}
                  image={thirdImage}
                  variants={[
                    'landscape-crop',
                    'landscape-crop2x',
                    'landscape-crop4x',
                    'landscape-crop6x',
                  ]}
                />
              </>
            )}
            {viewPhotosButton}
          </div>
        </div>
      )}

      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
