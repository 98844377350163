import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
      <strong>Pairables Marketplace Ltd. Terms of Use Agreement</strong>
      </p>
      <p>
      Last updated: April 1, 2021
      </p>
      <p>
      We provide the <strong>“Platform” </strong>located at <a href="http://www.pairables.ca/">www.pairables.ca</a>, to you subject to notices, terms, conditions, and policies set forth in the following Privacy Policy and Our Terms of Use Agreement <strong>(“Agreement”). </strong>Please read the Agreement first to understand the meaning of words used in the Terms and to understand what the Platform is and Our relationship with You. We do require certain information to run the Platform and help with your booking procedures. If you wish to not provide certain information, please contact us.
      </p>
      <p>
      This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Platform offered by Pairables Marketplace Ltd. and tells You about Your privacy rights. We use Your Personal data to provide and improve the Platform. By using the Platform, You agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Platform. Your continued use of the Platform following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
      </p>
      <h2><strong>Collecting and Using Your Personal Data</strong></h2>

      <h2><strong>We may collect information in the following ways:</strong></h2>

      <ol>

      <li>Filling in forms on our website, registering for an account, posting a Pairable, or by corresponding with us by phone, e-mail or otherwise and includes information you provide when you register to use the website, and use the services provided by the website.</li>

      <li>We will collect information generated by your visit to the website. This may include your IP Address (an IP address is a number that is automatically assigned to your computer whenever you are surfing the Internet); the type of browser, operating system and device used to access the website; the date and time you access our website; the pages you visit; and if you visited our website from another website, the address of that other website. Some of this information may be stored on your computer in the form of a cookie. This information helps the web server track such things as your preferences and any data you submit while browsing our website.</li>

      <li>We may monitor, record, store and use any telephone, email or other communication with you to improve our Services and Platform.</li>

      </ol>

      <h2><strong>Types of Data Collected</strong></h2>

      <h4><strong><em>Personal Data</em></strong></h4>

      <p>
          Personally identifiable information may include, but is not limited to:
      </p>

      <ol>

        <li>Name</li>

        <li>Date of Birth</li>

        <li>Home Address</li>

        <li>Work Address</li>

        <li>Personal Email</li>

        <li>Business Email</li>

        <li>Home Telephone Number</li>

        <li>Mobile Telephone Number</li>

        <li>Bank account information for payouts</li>

        <li>Visa or Credit Card information for Bookings</li>

        <li>Any other information that you provide to us</li>

      </ol>

      <h4><strong><em>Usage Data</em></strong></h4>

      <p>
      Usage Data is collected automatically when using the Platform.
      </p>
      <p>
          Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Platform that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
      </p>
      <p>
          When You access the Platform by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
      </p>
      <p>
          We may also collect information that Your browser sends whenever You visit our Platform or when You access the Platform by or through a mobile device.
      </p>
      <h2><strong>Use of Your Personal Data</strong></h2>

      <p>
      The Company may use Personal Data for the following purposes:
      </p>
      <p>
          <strong>To provide and maintain our Service</strong>, including to monitor the usage of our Platform.
      </p>
      <p>
          <strong>To manage Your Account:</strong> to manage Your registration as a user of the Platform. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
      </p>
      <p>
          <strong>For the performance of a contract:</strong> the development, compliance and undertaking of the Terms
      </p>
      <p>
          <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
      </p>
      <p>
          <strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
      </p>
      <p>
          <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
      </p>
      <p>
          <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
      </p>
      <p>
          <strong>For your Listing Page:</strong> Whatever you can see about another Member on the Platform such as on a Listing Page or Profile Page, other Users of the Platform can see about You.
      </p>
      <p>
          <strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
      </p>
      <h2><strong>Sharing of your Personal Information</strong></h2>
      <p>
      We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users and some of your personal information with our business partners, trusted affiliates and advertisers for the purposes outlined above in the following ways:
      </p>

      <ol>

      <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Platform, for payment processing, to contact You, and for any other services that help with your booking procedures on the Platform.</li>

      <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>

      <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>

      <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>

      <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>

      <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>

      <li>We also may use social buttons provided by services like Facebook. Your use of these third party services is entirely optional. We are not responsible for the privacy policies and/or practices of these third party services, and you are responsible for reading and understanding those third party services privacy policies.</li>

      </ol>

      <h2><strong>Retention of Your Personal Data</strong></h2>

      <p>
      The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
      </p>
      <p>
      The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
      </p>
      <h2><strong>Disclosure of Your Personal Data</strong></h2>

      <h4><strong><em>Business Transactions</em></strong></h4>

      <p>
      If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
      </p>
      <h4><strong><em>Law enforcement</em></strong></h4>

      <p>
      Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
      </p>
      <h4><strong><em>Other legal requirements</em></strong></h4>

      <p>
      The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
      </p>

      <ol>

      <li>Comply with a legal obligation</li>

      <li>Protect and defend the rights or property of the Company</li>

      <li>Prevent or investigate possible wrongdoing in connection with the Service</li>

      <li>Protect the personal safety of Users of the Service or the public</li>

      <li>Protect against legal liability</li>

      </ol>

      <h2><strong>Security of Your Personal Data</strong></h2>

      <p>
      The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
      </p>
      <p>
      You are not obligated to provide your personal information to us, however, as certain information is required for us to provide you access to the Platform and for Us to run the Platform, we may not be able to offer you access to Platform or allow you to use the Platform.
      </p>
      <h2><strong>How We Protect Your Information</strong></h2>

      <p>
      We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Platform.
      </p>

      <h2><strong>How to remove personal data from <a href="https://pairables.ca/">Pairables.ca</a></strong></h2>

      <ol>

      <li>You may remove information from your listings by going to "Your Listings" and click on the Listing you would like to edit to take you to the Listing's page. From here, you can click "edit" on the top right to edit all information about this listing.</li>

      <li> You can "close" a listing in "Your Listings" which will hide the listing from the public until you re-open that listing.</li>

      <li>You can email <a href = "mailto: info@pairables.ca">info@pairables.ca</a> to delete a listing.</li>

      <li>You can edit/delete your bio and name under "Profile Settings".</li>

      <li>You can add/edit/remove your email, phone number, payment details and payout details under "account settings".</li>

      <li>You can permanently delete your account and information by emailing <a href = "mailto: info@pairables.ca">info@pairables.ca</a> and asking to delete your account.</li>

      </ol>

      <h2><strong>Web Browser Cookies</strong></h2>

      <p>
      A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Platform. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Platform may use Cookies.
      </p>
      <p>
      We may use similar tracking technologies to track the activity on Our Platform and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
      </p>
      <p>
      <strong>Flash Cookies.</strong> Certain features or services on the Platform may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available at <a href="https://www.adobe.com/products/flashplayer/end-of-life.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">helpx.adobe.com</a>
      </p>
      <p>
      <strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
      </p>
      <h2><strong>Payments</strong></h2>

      <p>
      We use a third-party service provider for payment processing (e.g. payment processors), Stripe. Please review Stripe’s Privacy Policy on their website <a href="https://stripe.com/">www.stripe.com</a>.
      </p>
      <h2><strong>Links to Other Websites</strong></h2>

      <p>
      Our Platform may contain links to other websites that are not operated by Us. If You click on a third-party link, You may be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
      </p>
      <p>
      We have no control over and assume no responsibility for the content, terms of use, privacy policies or practices of any third party sites or services.
      </p>
      <h2><strong>Contact Us</strong></h2>

      <p>
      If you have any questions about this Privacy Policy or the Agreement, You can contact us:
      </p>
      <p>
      By email: <a href = "mailto: info@pairables.ca">info@pairables.ca</a>
      </p>
      <p>
      By visiting this page on our website: <a href="http://www.pairables.ca/">www.pairables.ca</a>
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
