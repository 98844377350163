import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import filter from 'lodash/filter';
import classNames from 'classnames';
import moment from 'moment';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import * as validators from '../../util/validators';
import {
  Form,
  IconSpinner,
  FieldSelect,
  PrimaryButton,
  FieldDateRangeInput,
  NamedLink,
  ExternalLink,
} from '../../components';

import { countFollowings } from '../../containers/ListingPage/ListingPage.duck';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingDatesForm.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.bookNow = this.bookNow.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    if (!e.amount) {
      e['amount'] = 1;
    }
    if (!e.term) {
      e['term'] = 25;
    }
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};
    const amount = formValues.values && formValues.values.amount ? formValues.values.amount : 1;
    const term = formValues.values && formValues.values.term ? formValues.values.term : 25;
    const time = formValues.values && formValues.values.time;
    const period = formValues.values && formValues.values.period;
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, amount, term, time, period },
        listingId,
        isOwnListing,
      });
    }
  }

  bookNow() {
    const { listingId, isFareharbor, followings, onCountFollowings } = this.props;
    if (isFareharbor) {
      onCountFollowings(listingId, followings);
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      amountOptions,
      termOptions,
      timeOptions,
      periodOptions,
      methodOptions,
      currentUser,
      isFareharbor,
      damageDeposit,
      paymentMethod,
      region,
      capacity,
      quantity,
      seats,
      link,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const filteredAmountOptions = filter(amountOptions, function(option) {
      return parseInt(option.label, 10) <= quantity || parseInt(option.label, 10) <= capacity;
    });

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const hasAttributes = currentUser && currentUser.attributes;
    const userHasProfile = hasAttributes && currentUser.attributes.profile;
    const userHasPublicData = userHasProfile && currentUser.attributes.profile.publicData;
    const userIsVerified =
      userHasPublicData && currentUser.attributes.profile.publicData.verification === 'approved';

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            listingCategory,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const { amount, term, time, period } = values;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  term,
                  time,
                  period,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                damageDeposit={damageDeposit}
                paymentMethod={paymentMethod}
                region={region}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const bookingDisabledMaybe =
            !isFareharbor && !userIsVerified ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.userIsNotVerified" />
              </span>
            ) : null;

          const verificationStartedMaybe =
            !isFareharbor && !userIsVerified ? (
              <p className={css.links}>
                <NamedLink
                  className={css.link}
                  name="ProfileSettingsPage"
                  to={{ hash: '#veriff-root' }}
                >
                  <FormattedMessage id="BookingDatesForm.startVerification" />
                </NamedLink>
              </p>
            ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const amountLabel = intl.formatMessage({
            id: 'BookingDatesForm.selectAmountLabel',
          });

          const amountRequired = validators.required(
            intl.formatMessage({
              id: 'BookingDatesForm.selectAmountRequired',
            })
          );

          const periodLabel = intl.formatMessage({
            id: 'BookingDatesForm.selectPeriodLabel',
          });

          const periodRequired = validators.required(
            intl.formatMessage({
              id: 'BookingDatesForm.selectPeriodRequired',
            })
          );

          const termLabel = intl.formatMessage({
            id: 'BookingDatesForm.selectTermLabel',
          });

          const termRequired = validators.required(
            intl.formatMessage({
              id: 'BookingDatesForm.selectTermRequired',
            })
          );

          const timeLabel = intl.formatMessage({
            id: 'BookingDatesForm.selectTimeLabel',
          });

          const timeRequired = validators.required(
            intl.formatMessage({
              id: 'BookingDatesForm.selectTimeRequired',
            })
          );

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          const sameDay = (start, end) => {
            const newStart = moment(start);
            const newEnd = moment(end);
            return newEnd.diff(newStart, 'days');
          };
          const isException =
            listingCategory === 'vacation_properties' || listingCategory === 'rvs_and_campers';
          const isHourly =
            !isException && (startDate && endDate && sameDay(startDate, endDate) === 1);
          const moreThanLess = (quantity || capacity) > 1;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              {!isFareharbor && (
                <FieldDateRangeInput
                  className={css.bookingDates}
                  name="bookingDates"
                  unitType={unitType}
                  startDateId={`${formId}.bookingStartDate`}
                  startDateLabel={bookingStartLabel}
                  startDatePlaceholderText={startDatePlaceholderText}
                  endDateId={`${formId}.bookingEndDate`}
                  endDateLabel={bookingEndLabel}
                  endDatePlaceholderText={endDatePlaceholderText}
                  focusedInput={this.state.focusedInput}
                  onFocusedInputChange={this.onFocusedInputChange}
                  format={identity}
                  timeSlots={timeSlots}
                  useMobileMargins
                  validate={composeValidators(
                    required(requiredMessage),
                    bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                  )}
                  disabled={fetchLineItemsInProgress}
                />
              )}

              {moreThanLess && showEstimatedBreakdown ? (
                <FieldSelect
                  id="amount"
                  name="amount"
                  label={amountLabel}
                  validate={amountRequired}
                >
                  <option disabled value="">
                    {'Amount'}
                  </option>
                  {filteredAmountOptions.length > 0 &&
                    filteredAmountOptions.map(amount => {
                      return (
                        <option value={amount.key} key={amount.key}>
                          {amount.label}
                        </option>
                      );
                    })}
                </FieldSelect>
              ) : null}

              {isHourly ? (
                <FieldSelect id="term" name="term" label={termLabel} validate={termRequired}>
                  <option disabled value="">
                    {'Term'}
                  </option>
                  {termOptions.length > 0 &&
                    termOptions.map(term => {
                      return (
                        <option value={term.key} key={term.key}>
                          {term.label}
                        </option>
                      );
                    })}
                </FieldSelect>
              ) : null}

              {showEstimatedBreakdown ? (
                <FieldSelect id="time" name="time" label={timeLabel} validate={timeRequired}>
                  <option disabled value="">
                    {'Time'}
                  </option>
                  {timeOptions.length > 0 &&
                    timeOptions.map(time => {
                      return (
                        <option value={time.key} key={time.key}>
                          {time.label}
                        </option>
                      );
                    })}
                </FieldSelect>
              ) : null}

              {showEstimatedBreakdown ? (
                <FieldSelect
                  id="period"
                  name="period"
                  label={periodLabel}
                  validate={periodRequired}
                >
                  <option disabled value="">
                    {'Period'}
                  </option>
                  {periodOptions.length > 0 &&
                    periodOptions.map(period => {
                      return (
                        <option value={period.key} key={period.key}>
                          {period.label}
                        </option>
                      );
                    })}
                </FieldSelect>
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {!isFareharbor && (
                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>
              )}

              {!isFareharbor ? (
                <div className={submitButtonClasses}>
                  <PrimaryButton type="submit" disabled={!userIsVerified}>
                    <FormattedMessage id="BookingDatesForm.requestToBook" />
                  </PrimaryButton>
                </div>
              ) : (
                <ExternalLink href={link} className={css.fareharborLink} onClick={() => this.bookNow()}>
                  <FormattedMessage id="BookingDatesForm.bookNow" />
                </ExternalLink>
              )}

              {bookingDisabledMaybe}
              {verificationStartedMaybe}
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onCountFollowings: (listingId, views) => dispatch(countFollowings(listingId, views)),
});

const BookingDatesForm = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
