import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDetailsMaybe = props => {
  const { listing } = props;
  const descriptionData = listing.attributes.publicData && listing.attributes.publicData;
  const category = descriptionData && descriptionData.category;
  const isVacationProperties = category === 'vacation_properties';
  const isElectric = category === 'electric';
  const isVehicles = category === 'vehicles';
  const isRvsAndCampers = category === 'rvs_and_campers';
  const isEverythingElse = !isVacationProperties && !isElectric && !isVehicles && !isRvsAndCampers;

  const vacationPropertiesCapacityMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vacationPropertiesCapacity" />
  );
  const vacationPropertiesBedsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vacationPropertiesBeds" />
  );
  const vacationPropertiesBedroomsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vacationPropertiesBedrooms" />
  );
  const vacationPropertiesBathroomsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vacationPropertiesBathrooms" />
  );
  const watercraftsCaptainMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsCaptain" />
  );
  const watercraftsQuantityMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsQuantity" />
  );
  const watercraftsConditionMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsCondition" />
  );
  const watercraftsDemonstrationMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsDemonstration" />
  );
  const watercraftsLicenseMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsLicense" />
  );
  const watercraftsLengthMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsLength" />
  );
  const watercraftsGuestsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsGuests" />
  );
  const watercraftsModelMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsModel" />
  );
  const watercraftsFuelMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsFuel" />
  );
  const watercraftsLevelsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsLevels" />
  );
  const watercraftsYearMessage = (
    <FormattedMessage id="EditListingDescriptionForm.watercraftsYear" />
  );
  const vehiclesAccidentMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesAccident" />
  );
  const vehiclesSeatsMessage = <FormattedMessage id="EditListingDescriptionForm.vehiclesSeats" />;
  const vehiclesBeltsMessage = <FormattedMessage id="EditListingDescriptionForm.vehiclesBelts" />;
  const vehiclesDriverMessage = <FormattedMessage id="EditListingDescriptionForm.vehiclesDriver" />;
  const vehiclesQuantityMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesQuantity" />
  );
  const vehiclesConditionMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesCondition" />
  );
  const vehiclesDemonstrationMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesDemonstration" />
  );
  const vehiclesLicenseMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesLicense" />
  );
  const vehiclesLevelsMessage = <FormattedMessage id="EditListingDescriptionForm.vehiclesLevels" />;
  const vehiclesMileageMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesMileage" />
  );
  const vehiclesFuelMessage = <FormattedMessage id="EditListingDescriptionForm.vehiclesFuel" />;
  const vehiclesTransmissionMessage = (
    <FormattedMessage id="EditListingDescriptionForm.vehiclesTransmission" />
  );
  const vehiclesYearMessage = <FormattedMessage id="EditListingDescriptionForm.vehiclesYear" />;
  const rvsAndCampersAccidentMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersAccident" />
  );
  const rvsAndCampersQuantityMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersQuantity" />
  );
  const rvsAndCampersConditionMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersCondition" />
  );
  const rvsAndCampersDemonstrationMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersDemonstration" />
  );
  const rvsAndCampersLicenseMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersLicense" />
  );
  const rvsAndCampersLengthMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersLength" />
  );
  const rvsAndCampersTransmissionMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersTransmission" />
  );
  const rvsAndCampersCapacityMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersCapacity" />
  );
  const rvsAndCampersSlideoutsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersSlideouts" />
  );
  const rvsAndCampersBedsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersBeds" />
  );
  const rvsAndCampersSeatsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersSeats" />
  );
  const rvsAndCampersBeltsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersBelts" />
  );
  const rvsAndCampersFuelMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersFuel" />
  );
  const rvsAndCampersLevelsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersLevels" />
  );
  const rvsAndCampersYearMessage = (
    <FormattedMessage id="EditListingDescriptionForm.rvsAndCampersYear" />
  );
  const everythingElseGuestsMessage = (
    <FormattedMessage id="EditListingDescriptionForm.everythingElseGuests" />
  );
  const everythingElseConditionMessage = (
    <FormattedMessage id="EditListingDescriptionForm.everythingElseCondition" />
  );
  const everythingElseQuantityMessage = (
    <FormattedMessage id="EditListingDescriptionForm.everythingElseQuantity" />
  );
  const everythingElseIncludedMessage = (
    <FormattedMessage id="EditListingDescriptionForm.everythingElseIncluded" />
  );
  const everythingElseDemonstrationMessage = (
    <FormattedMessage id="EditListingDescriptionForm.everythingElseDemonstration" />
  );
  const everythingElseYearMessage = (
    <FormattedMessage id="EditListingDescriptionForm.everythingElseYear" />
  );

  return descriptionData ? (
    <div className={classNames(css.sectionDescription, css.sectionDetails)}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </h2>
      {isVacationProperties && descriptionData.capacity ? (
        <div>
          <h3 className={css.descriptionTitle}>{vacationPropertiesCapacityMessage}</h3>
          <p className={css.description}>{descriptionData.capacity}</p>
        </div>
      ) : null}

      {isVacationProperties && descriptionData.beds ? (
        <div>
          <h3 className={css.descriptionTitle}>{vacationPropertiesBedsMessage}</h3>
          <p className={css.description}>{descriptionData.beds}</p>
        </div>
      ) : null}

      {isVacationProperties && descriptionData.bedrooms ? (
        <div>
          <h3 className={css.descriptionTitle}>{vacationPropertiesBedroomsMessage}</h3>
          <p className={css.description}>{descriptionData.bedrooms}</p>
        </div>
      ) : null}

      {isVacationProperties && descriptionData.bathrooms ? (
        <div>
          <h3 className={css.descriptionTitle}>{vacationPropertiesBathroomsMessage}</h3>
          <p className={css.description}>{descriptionData.bathrooms}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.captain ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsCaptainMessage}</h3>
          <p className={css.description}>{descriptionData.captain}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.quantity ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsQuantityMessage}</h3>
          <p className={css.description}>{descriptionData.quantity}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.condition ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsConditionMessage}</h3>
          <p className={css.description}>{descriptionData.condition}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.demonstration ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsDemonstrationMessage}</h3>
          <p className={css.description}>{descriptionData.demonstration}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.license ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsLicenseMessage}</h3>
          <p className={css.description}>{descriptionData.license}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.length ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsLengthMessage}</h3>
          <p className={css.description}>{descriptionData.length}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.guests ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsGuestsMessage}</h3>
          <p className={css.description}>{descriptionData.guests}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.model ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsModelMessage}</h3>
          <p className={css.description}>{descriptionData.model}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.fuel ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsFuelMessage}</h3>
          <p className={css.description}>{descriptionData.fuel}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.levels ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsLevelsMessage}</h3>
          <p className={css.description}>{descriptionData.levels}</p>
        </div>
      ) : null}

      {isElectric && descriptionData.year ? (
        <div>
          <h3 className={css.descriptionTitle}>{watercraftsYearMessage}</h3>
          <p className={css.description}>{descriptionData.year}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.accident ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesAccidentMessage}</h3>
          <p className={css.description}>{descriptionData.accident}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.seats ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesSeatsMessage}</h3>
          <p className={css.description}>{descriptionData.seats}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.belts ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesBeltsMessage}</h3>
          <p className={css.description}>{descriptionData.belts}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.driver ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesDriverMessage}</h3>
          <p className={css.description}>{descriptionData.driver}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.quantity ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesQuantityMessage}</h3>
          <p className={css.description}>{descriptionData.quantity}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.condition ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesConditionMessage}</h3>
          <p className={css.description}>{descriptionData.condition}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.demonstration ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesDemonstrationMessage}</h3>
          <p className={css.description}>{descriptionData.demonstration}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.license ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesLicenseMessage}</h3>
          <p className={css.description}>{descriptionData.license}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.levels ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesLevelsMessage}</h3>
          <p className={css.description}>{descriptionData.levels}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.mileage ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesMileageMessage}</h3>
          <p className={css.description}>{descriptionData.mileage}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.fuel ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesFuelMessage}</h3>
          <p className={css.description}>{descriptionData.fuel}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.transmission ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesTransmissionMessage}</h3>
          <p className={css.description}>{descriptionData.transmission}</p>
        </div>
      ) : null}

      {isVehicles && descriptionData.year ? (
        <div>
          <h3 className={css.descriptionTitle}>{vehiclesYearMessage}</h3>
          <p className={css.description}>{descriptionData.year}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.accident ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersAccidentMessage}</h3>
          <p className={css.description}>{descriptionData.accident}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.quantity ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersQuantityMessage}</h3>
          <p className={css.description}>{descriptionData.quantity}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.condition ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersConditionMessage}</h3>
          <p className={css.description}>{descriptionData.condition}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.demonstration ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersDemonstrationMessage}</h3>
          <p className={css.description}>{descriptionData.demonstration}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.license ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersLicenseMessage}</h3>
          <p className={css.description}>{descriptionData.license}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.length ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersLengthMessage}</h3>
          <p className={css.description}>{descriptionData.length}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.transmission ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersTransmissionMessage}</h3>
          <p className={css.description}>{descriptionData.transmission}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.capacity ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersCapacityMessage}</h3>
          <p className={css.description}>{descriptionData.capacity}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.slideouts ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersSlideoutsMessage}</h3>
          <p className={css.description}>{descriptionData.slideouts}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.beds ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersBedsMessage}</h3>
          <p className={css.description}>{descriptionData.beds}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.seats ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersSeatsMessage}</h3>
          <p className={css.description}>{descriptionData.seats}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.belts ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersBeltsMessage}</h3>
          <p className={css.description}>{descriptionData.belts}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.fuel ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersFuelMessage}</h3>
          <p className={css.description}>{descriptionData.fuel}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.levels ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersLevelsMessage}</h3>
          <p className={css.description}>{descriptionData.levels}</p>
        </div>
      ) : null}

      {isRvsAndCampers && descriptionData.year ? (
        <div>
          <h3 className={css.descriptionTitle}>{rvsAndCampersYearMessage}</h3>
          <p className={css.description}>{descriptionData.year}</p>
        </div>
      ) : null}

      {isEverythingElse && descriptionData.guests ? (
        <div>
          <h3 className={css.descriptionTitle}>{everythingElseGuestsMessage}</h3>
          <p className={css.description}>{descriptionData.guests}</p>
        </div>
      ) : null}

      {isEverythingElse && descriptionData.condition ? (
        <div>
          <h3 className={css.descriptionTitle}>{everythingElseConditionMessage}</h3>
          <p className={css.description}>{descriptionData.condition}</p>
        </div>
      ) : null}

      {isEverythingElse && descriptionData.quantity ? (
        <div>
          <h3 className={css.descriptionTitle}>{everythingElseQuantityMessage}</h3>
          <p className={css.description}>{descriptionData.quantity}</p>
        </div>
      ) : null}

      {isEverythingElse && descriptionData.included ? (
        <div>
          <h3 className={css.descriptionTitle}>{everythingElseIncludedMessage}</h3>
          <p className={css.description}>{descriptionData.included}</p>
        </div>
      ) : null}

      {isEverythingElse && descriptionData.demonstration ? (
        <div>
          <h3 className={css.descriptionTitle}>{everythingElseDemonstrationMessage}</h3>
          <p className={css.description}>{descriptionData.demonstration}</p>
        </div>
      ) : null}

      {isEverythingElse && descriptionData.year ? (
        <div>
          <h3 className={css.descriptionTitle}>{everythingElseYearMessage}</h3>
          <p className={css.description}>{descriptionData.year}</p>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
