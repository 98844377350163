import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CustomVariantsSelectFieldMaybe = props => {
  const { name, id, variants, intl, label } = props;
  return variants ? (
    <FieldSelect className={css.firstColRoot} name={name} id={id} label={label}>
      {variants.map(variant => (
        <option key={variant} value={variant}>
          {variant}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomVariantsSelectFieldMaybe;
