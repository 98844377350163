import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldCheckbox } from '../../components';
import css from './EditListingPricingForm.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => {
  const { isException } = props;

  const PRICING_OPTIONS =
    isException === true
      ? ['per_night', 'per_week', 'per_month']
      : ['per_hour', 'per_day', 'per_week', 'per_month'];

  return (
    <FinalForm
      {...props}
      initialValues={{
        pricingOptions: PRICING_OPTIONS.reduce((acc, curr) => {
          acc[curr] = null;
          return acc;
        }, {}),
        selectedOptions: PRICING_OPTIONS.reduce((acc, curr) => {
          acc[curr] = false;
          return acc;
        }, {}),
        ...props.initialValues,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
        } = formRenderProps;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const translationKey = option => {
          if (option === 'per_hour') {
            return 'EditListingPricingForm.pricePerHour';
          } else if (option === 'per_day') {
            return 'EditListingPricingForm.pricePerDay';
          } else if (option === 'per_night') {
            return 'EditListingPricingForm.pricePerNight';
          } else if (option === 'per_week') {
            return 'EditListingPricingForm.pricePerWeek';
          } else if (option === 'per_month') {
            return 'EditListingPricingForm.pricePerMonth';
          }
        };

        const pricePerUnitMessage = option =>
          intl.formatMessage({
            id: translationKey(option),
          });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            {PRICING_OPTIONS.map(option => {
              const enabled = values.selectedOptions[option];

              return (
                <div key={option} className={css.pricingOption}>
                  <FieldCheckbox
                    id={`option_${option}`}
                    name={`selectedOptions.${option}`}
                    useSuccessColor
                    parse={value => {
                      form.resetFieldState(`pricingOptions.${option}`);
                      form.change(`pricingOptions.${option}`, null);
                      return value;
                    }}
                  />
                  <FieldCurrencyInput
                    id={`${option}_price`}
                    name={`pricingOptions.${option}`}
                    disabled={!enabled}
                    className={classNames(css.priceInput, { [css.disabled]: !enabled })}
                    label={pricePerUnitMessage(option)}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={config.currencyConfig}
                    validate={enabled ? priceValidators : () => {}}
                  />
                </div>
              );
            })}

            <p className={css.declaimer}>
              It is your responsibility to include any required local, municipal, provincial, and
              federal taxes in the price. Pairables Marketplace Ltd. only charges applicable taxes
              on User Fees. Please read the Terms of Use Agreement for more information.
            </p>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
