import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink, ReviewRating } from '../../components';

import css from './FeaturedTourListings.css';

const FeaturedTourListingCard = ({ listing }) => {
  const { verified, delivery, letsGo } = listing.attributes;

  const title = listing.attributes.title;
  const pricingOptions =
    listing.attributes.publicData && listing.attributes.publicData.pricingOptions;

  const roundAndTruncate = number => {
    const truncatedNumber = (number - (number % 100)) / 100;
    const roundedNumber = (Math.ceil(truncatedNumber * 100) / 100).toFixed(2);
    return roundedNumber.replace(/\./g, '.');
  };

  const hourlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_hour'])}`;
  const hourlyTitle = 'ListingPage.perHour';
  const dailyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_day'])}`;
  const dailyTitle = 'ListingPage.perDay';
  const nightlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_night'])}`;
  const nightlyTitle = 'ListingPage.perNight';
  const weeklyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_week'])}`;
  const weeklyTitle = 'ListingPage.perWeek';
  const monthlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_month'])}`;
  const monthlyTitle = 'ListingPage.perMonth';

  const checkmark = (
    <svg width="14" height="10" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.708,7.667l7.667,-7.667l1.167,1.167l-8.834,8.833l-4.708,-4.708l1.167,-1.167l3.541,3.542Z"
        fill="#36a251"
      />
    </svg>
  );
  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const backgroundImages = listingImages(listing, 'landscape-crop');
  const backgroundImage =
    backgroundImages && backgroundImages.length > 0 && backgroundImages[0].url;

  return (
    <NamedLink
      name="ListingPage"
      params={{ id: listing.id.uuid, slug: createSlug(title) }}
      className={css.featuredListing}
    >
      <div className={css.featuredListingImage}>
        <img src={backgroundImage} />
      </div>
      <div className={css.featuredListingTitle}>{title}</div>
      <div className={css.featuredListingInfo}>
        {pricingOptions && pricingOptions['per_hour'] ? (
          <>
            <div className={css.featuredListingPrice}>
              <FormattedMessage id="FeaturedListings.startingPrice" />
              {hourlyPrice}
            </div>
            <div className={css.featuredListingPrice}>
              &nbsp;
              <FormattedMessage id={hourlyTitle} />
            </div>
          </>
        ) : null}
        {pricingOptions && pricingOptions['per_day'] && !pricingOptions['per_hour'] ? (
          <>
            <div className={css.featuredListingPrice}>
              <FormattedMessage id="FeaturedListings.startingPrice" />
              {dailyPrice}
            </div>
            <div className={css.featuredListingPrice}>
              &nbsp;
              <FormattedMessage id={dailyTitle} />
            </div>
          </>
        ) : null}
        {pricingOptions && pricingOptions['per_night'] && !pricingOptions['per_hour'] ? (
          <>
            <div className={css.featuredListingPrice}>
              <FormattedMessage id="FeaturedListings.startingPrice" />
              {nightlyPrice}
            </div>
            <div className={css.featuredListingPrice}>
              &nbsp;
              <FormattedMessage id={nightlyTitle} />
            </div>
          </>
        ) : null}
        {pricingOptions &&
        pricingOptions['per_week'] &&
        !pricingOptions['per_hour'] &&
        !pricingOptions['per_day'] &&
        !pricingOptions['per_night'] ? (
          <>
            <div className={css.featuredListingPrice}>
              <FormattedMessage id="FeaturedListings.startingPrice" />
              {weeklyPrice}
            </div>
            <div className={css.featuredListingPrice}>
              &nbsp;
              <FormattedMessage id={weeklyTitle} />
            </div>
          </>
        ) : null}
        {pricingOptions &&
        pricingOptions['per_month'] &&
        !pricingOptions['per_week'] &&
        !pricingOptions['per_hour'] &&
        !pricingOptions['per_day'] &&
        !pricingOptions['per_night'] ? (
          <>
            <div className={css.featuredListingPrice}>
              <FormattedMessage id="FeaturedListings.startingPrice" />
              {monthlyPrice}
            </div>
            <div className={css.featuredListingPrice}>
              &nbsp;
              <FormattedMessage id={monthlyTitle} />
            </div>
          </>
        ) : null}
        <div className={css.featuredListingVerification}>
          {verified ? (
            <>
              {checkmark}
              <FormattedMessage id="FeaturedListings.verified" />
            </>
          ) : null}
          {delivery ? (
            <>
              {checkmark}
              <FormattedMessage id="FeaturedListings.delivery" />
            </>
          ) : null}
          {letsGo ? (
            <>
              {checkmark}
              <FormattedMessage id="FeaturedListings.letsGo" />
            </>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

const FeaturedListings = props => {
  const { title, category, listings } = props;

  return (
    <div className={css.root}>
      <div className={css.header}>
        <div className={css.title}>
          <strong>
            <FormattedMessage id="FeaturedListings.titleAlt" values={{ title }} />
          </strong>
        </div>
        <NamedLink name="SearchPage" to={{ search: `?pub_category=tours_and_adventures` }} className={css.browseButton}>
          <FormattedMessage id="FeaturedListings.buttonText" />
        </NamedLink>
      </div>
      <div className={css.listings}>
        {listings.length &&
          listings.length > 0 &&
          listings.map(listing => <FeaturedTourListingCard key={listing._id} listing={listing} />)}
      </div>
    </div>
  );
};

FeaturedListings.defaultProps = { location: null };

const { string, array } = PropTypes;

FeaturedListings.propTypes = {
  title: string.isRequired,
  location: string,
  category: string.isRequired,
  listings: array.isRequired,
};

export default FeaturedListings;
