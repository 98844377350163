import React, { useRef } from 'react';
import { array, bool, func, oneOf, object, shape, string, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import unionWith from 'lodash/unionWith';
import filter from 'lodash/filter';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import {
  Page,
  SectionCategories,
  SectionHero,
  SectionHeroDouble,
  SectionHowItWorks,
  FeaturedListings,
  FeaturedTourListings,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  searchListings,
} from '../SearchPage/SearchPage.duck';

import facebookImage from '../../assets/teachliveFacebook-1200x630.jpg';
import twitterImage from '../../assets/teachliveTwitter-600x314.jpg';
import css from './LandingPage.css';

const RESULT_PAGE_SIZE = 100;
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1280;

const scrollToElement = (ref, isTabletLayout) => {
  if (ref && ref.current) {
    if (isTabletLayout) {
      window.scrollTo(0, ref.current.offsetTop - 140);
    } else {
      // Required for smooth scroll on desktop including Safari
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};

export const LandingPageComponent = props => {
  const { intl, listings, history, location, scrollingDisabled, viewport } = props;

  const categoriesRef = useRef(null);
  const featuredRef = useRef(null);
  const featuredTourRef = useRef(null);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout =
    viewport.width >= MAX_MOBILE_SCREEN_WIDTH && viewport.width < MAX_TABLET_SCREEN_WIDTH;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const featuredListings = filter(listings, function(listing) {
    return listing.attributes.publicData && listing.attributes.publicData.featured && !listing.attributes.publicData.featuredTour;
  });
  const featuredTourListings = filter(listings, function(listing) {
    return listing.attributes.publicData && listing.attributes.publicData.featured && listing.attributes.publicData.featuredTour;
  });

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              isMobileLayout={isMobileLayout}
              categoriesRef={categoriesRef}
              handleScrollToCategories={() => scrollToElement(categoriesRef, isTabletLayout)}
              handleScrollToFeatured={() => scrollToElement(featuredRef, isTabletLayout)}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCategories />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.heroContainer}>
                <SectionHeroDouble
                  className={css.heroDouble}
                  history={history}
                  location={location}
                  isMobileLayout={isMobileLayout}
                  handleScrollToFeaturedTour={() => scrollToElement(featuredTourRef, isTabletLayout)}
                />
              </div>
            </li>
            <li className={css.section} ref={featuredRef}>
              <div className={css.sectionContent}>
                <FeaturedListings key="featured" listings={featuredListings} />
              </div>
            </li>
            <li className={css.section} ref={featuredTourRef}>
              <div className={css.sectionContent}>
                <FeaturedTourListings key="featuredTour" listings={featuredTourListings} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

LandingPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
    pub_featured: true,
  });
};

export default LandingPage;
