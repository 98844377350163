import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, ExternalLink } from '../../components';
import config from '../../config';

import css from './ListingPage.css';

const SectionHeading = props => {
  const {
    priceTitle,
    pricingOptions,
    richTitle,
    richHeading,
    video,
    category,
    hostLink,
    showContactUser,
    onContactUser,
  } = props;

  const roundAndTruncate = number => {
    const truncatedNumber = (number - (number % 100)) / 100;
    const roundedNumber = (Math.ceil(truncatedNumber * 100) / 100).toFixed(2);
    return roundedNumber.replace(/\./g, '.');
  };
  const isException = category === 'vacation_properties' || category === 'rvs_and_campers';

  const hourlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_hour'])}`;
  const hourlyTitle = 'ListingPage.perHour';
  const dailyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_day'])}`;
  const dailyTitle = 'ListingPage.perDay';
  const nightlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_night'])}`;
  const nightlyTitle = 'ListingPage.perNight';
  const weeklyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_week'])}`;
  const weeklyTitle = 'ListingPage.perWeek';
  const monthlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_month'])}`;
  const monthlyTitle = 'ListingPage.perMonth';

  const formatUrl = url => {
    return new URL(url).hostname;
  };

  const videoLink = video ? (
    <ExternalLink key="videoLink" href={video}>
      {formatUrl(video)}
    </ExternalLink>
  ) : null;

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        {pricingOptions && pricingOptions['per_hour'] && !isException ? (
          <div>
            <div className={css.desktopPriceValue} title={hourlyTitle}>
              {hourlyPrice}{' '}
              <span className={css.desktopPerUnit}>
                <FormattedMessage id={hourlyTitle} />
              </span>
            </div>
          </div>
        ) : null}
        <div className={css.row}>
          {pricingOptions && pricingOptions['per_day'] && !isException ? (
            <div className={css.exceptionAlign}>
              <div className={css.desktopPriceValueException} title={dailyTitle}>
                {dailyPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={dailyTitle} />
              </div>
            </div>
          ) : null}
          {pricingOptions && pricingOptions['per_night'] && isException ? (
            <div>
              <div className={css.desktopPriceValue} title={nightlyTitle}>
                {nightlyPrice}{' '}
                <span className={css.desktopPerUnit}>
                  <FormattedMessage id={nightlyTitle} />
                </span>
              </div>
            </div>
          ) : null}
          {pricingOptions && pricingOptions['per_week'] ? (
            <div className={css.exceptionAlign}>
              <div className={css.desktopPriceValueException} title={weeklyTitle}>
                {weeklyPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={weeklyTitle} />
              </div>
            </div>
          ) : null}
          {pricingOptions && pricingOptions['per_month'] ? (
            <div className={css.exceptionAlign}>
              <div className={css.desktopPriceValueException} title={monthlyTitle}>
                {monthlyPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={monthlyTitle} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <h2>{richHeading}</h2>
        <div className={css.video}>{videoLink}</div>
        <br />
        <div className={css.author}>
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
