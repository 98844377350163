import React from 'react';
import { FieldTextInput } from '../../components';
import CustomOptionsSelectFieldMaybe from './CustomOptionsSelectFieldMaybe';
import CustomVariantsSelectFieldMaybe from './CustomVariantsSelectFieldMaybe';
import CustomTagMultiselectFieldMaybe from './CustomTagMultiselectFieldMaybe';
import { maxLength, required, composeValidators } from '../../util/validators';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const EverythingElseFields = ({ intl }) => {
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );

  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

  const everythingElseTitleMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseTitle',
  });
  const everythingElseTitlePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseTitlePlaceholder',
  });
  const everythingElseTitleRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseTitleRequired',
  });

  const everythingElseHeadlineMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseHeadline',
  });
  const everythingElseHeadlinePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseHeadlinePlaceholder',
  });
  const everythingElseHeadlineRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseHeadlineRequired',
  });

  const everythingElseDescriptionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseDescription',
  });
  const everythingElseDescriptionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseDescriptionPlaceholder',
  });
  const everythingElseDescriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseDescriptionRequired',
  });

  const everythingElseGuestsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseGuests',
  });
  const everythingElseGuestsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseGuestsPlaceholder',
  });
  const everythingElseGuestsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseGuestsRequired',
  });

  const everythingElseHostMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseHost',
  });
  const everythingElseHostPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseHostPlaceholder',
  });
  const everythingElseHostRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseHostRequired',
  });

  const everythingElseConditionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseCondition',
  });
  const everythingElseConditionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseConditionPlaceholder',
  });
  const everythingElseConditionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseConditionRequired',
  });

  const everythingElseQuantityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseQuantity',
  });
  const everythingElseQuantityPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseQuantityPlaceholder',
  });
  const everythingElseQuantityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseQuantityRequired',
  });

  const everythingElseLicenseMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseLicense',
  });
  const everythingElseLicensePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseLicensePlaceholder',
  });
  const everythingElseLicenseRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseLicenseRequired',
  });

  const everythingElseIncludedMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseIncluded',
  });
  const everythingElseIncludedPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseIncludedPlaceholder',
  });
  const everythingElseIncludedRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseIncludedRequired',
  });

  const everythingElseDemonstrationMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseDemonstration',
  });
  const everythingElseDemonstrationPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseDemonstrationPlaceholder',
  });
  const everythingElseDemonstrationRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseDemonstrationRequired',
  });

  const everythingElseYearMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseYear',
  });
  const everythingElseYearPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseYearPlaceholder',
  });
  const everythingElseYearRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.everythingElseYearRequired',
  });

  return (
    <>
      <FieldTextInput
        id="title"
        name="title"
        className={css.title}
        type="text"
        label={everythingElseTitleMessage}
        placeholder={everythingElseTitlePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(everythingElseTitleRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="headline"
        name="headline"
        className={css.title}
        type="text"
        label={everythingElseHeadlineMessage}
        placeholder={everythingElseHeadlinePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(everythingElseHeadlineRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="description"
        name="description"
        className={css.description}
        type="textarea"
        label={everythingElseDescriptionMessage}
        placeholder={everythingElseDescriptionPlaceholderMessage}
        rows="3"
        validate={composeValidators(required(everythingElseDescriptionRequiredMessage))}
      />

      <CustomTagMultiselectFieldMaybe id="tags" name="tags" intl={intl} />

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="guests"
          name="guests"
          className={css.firstColRoot}
          type="number"
          label={everythingElseGuestsMessage}
          placeholder={everythingElseGuestsPlaceholderMessage}
        />
        <CustomVariantsSelectFieldMaybe
          id="condition"
          name="condition"
          variants={['New', 'Almost new', 'Some wear and tear', "Don't judge"]}
          intl={intl}
          label={everythingElseConditionMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="quantity"
          name="quantity"
          className={css.firstColRoot}
          type="number"
          label={everythingElseQuantityMessage}
          placeholder={everythingElseQuantityPlaceholderMessage}
          defaultValue="1"
        />
        <CustomOptionsSelectFieldMaybe
          id="included"
          name="included"
          options={['Yes', 'No']}
          intl={intl}
          label={everythingElseIncludedMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="demonstration"
          name="demonstration"
          options={['Yes', 'No']}
          intl={intl}
          label={everythingElseDemonstrationMessage}
        />
        <FieldTextInput
          id="year"
          name="year"
          className={css.firstColRoot}
          type="number"
          label={everythingElseYearMessage}
          placeholder={everythingElseYearPlaceholderMessage}
        />
      </div>
    </>
  );
};

export default EverythingElseFields;
