import React from 'react';
import { filter } from 'lodash';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { ensureTransaction, ensureListing } from '../../util/data';
import { Button, FieldCheckboxGroup, FieldTextInput, Form } from '../../components';
import CustomAmenityMultiselectFieldMaybe from './CustomAmenityMultiselectFieldMaybe';

import css from './EditListingFeaturesForm.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        amenityOptions,
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        category,
      } = formRenderProps;

      const options = findOptionsForSelectFilter('amenities', filterConfig);
      const vacationPropertyOptions = filter(options, { category: 'vacation_properties' });
      const vehicleOptions = filter(options, { category: 'vehicles' });
      const rvsAndCamperOptions = filter(options, { category: 'rvs_and_campers' });

      const fieldsByCategory = category => {
        switch (category) {
          case 'vacation_properties':
            return (
              <div>
                <FieldCheckboxGroup
                  className={css.features}
                  id={name}
                  name={name}
                  options={vacationPropertyOptions}
                  twoColumns={true}
                />
                <CustomAmenityMultiselectFieldMaybe
                  id="customAmenities"
                  name="customAmenities"
                  amenityOptions={amenityOptions}
                  intl={intl}
                />
              </div>
            );
          case 'electric':
            return (
              <div>
                <CustomAmenityMultiselectFieldMaybe
                  id="customAmenities"
                  name="customAmenities"
                  amenityOptions={amenityOptions}
                  intl={intl}
                />
              </div>
            );
          case 'vehicles':
            return (
              <div>
                <FieldCheckboxGroup
                  className={css.features}
                  id={name}
                  name={name}
                  options={vehicleOptions}
                  twoColumns={true}
                />
                <CustomAmenityMultiselectFieldMaybe
                  id="customAmenities"
                  name="customAmenities"
                  amenityOptions={amenityOptions}
                  intl={intl}
                />
              </div>
            );
          case 'rvs_and_campers':
            return (
              <div>
                <FieldCheckboxGroup
                  className={css.features}
                  id={name}
                  name={name}
                  options={rvsAndCamperOptions}
                  twoColumns={true}
                />
                <CustomAmenityMultiselectFieldMaybe
                  id="customAmenities"
                  name="customAmenities"
                  amenityOptions={amenityOptions}
                  intl={intl}
                />
              </div>
            );
          default:
            return (
              <div>
                <CustomAmenityMultiselectFieldMaybe
                  id="customAmenities"
                  name="customAmenities"
                  amenityOptions={amenityOptions}
                  intl={intl}
                />
              </div>
            );
        }
      };

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {category && fieldsByCategory(category)}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
