import React from 'react';
import PropTypes from 'prop-types';
import { CategoryCarousel } from '../../components';
import classNames from 'classnames';
import config from '../../config';

import css from './SectionCategories.css';

const SectionCategories = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listingCategories = config.custom.listingCategories;
  return (
    <div className={classes}>
      {listingCategories.map(cat => (
        <CategoryCarousel key={cat.key} title={cat.title} items={cat.subcategories} />
      ))}
    </div>
  );
};

SectionCategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
