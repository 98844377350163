import React from 'react';
import { bool, func, object, string } from 'prop-types';
import map from 'lodash/map';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : publicData.category === undefined ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.createListingTitle"
      values={{ user: currentUser ? currentUser.attributes.profile.displayName : '' }}
    />
  ) : (
    <FormattedMessage
      id="EditListingDescriptionPanel.createDescriptionTitle"
      values={{ user: currentUser ? currentUser.attributes.profile.displayName : '' }}
    />
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const subCategoryOptions = findOptionsForSelectFilter('subcategory', config.custom.filters);
  const initialValues = { title, description, ...publicData };

  return (
    <div className={classes}>
      {currentUser && <h1 className={css.title}>{panelTitle}</h1>}
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            headline,
            category,
            subcategory,
            tags = [],
            guests,
            condition,
            quantity,
            included,
            demonstration,
            year,
            accident,
            license,
            length,
            transmission,
            capacity,
            slideouts,
            beds,
            seats,
            belts,
            fuel,
            levels,
            bedrooms,
            bathrooms,
            driver,
            mileage,
            captain,
            model,
          } = values;
          const keywordTags = map(tags, l => {
            return l.value;
          }).join(', ');

          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              headline,
              category,
              subcategory,
              tags: tags,
              keywordTags: keywordTags,
              guests,
              condition,
              quantity,
              included,
              demonstration,
              year,
              accident,
              license,
              length,
              transmission,
              capacity,
              slideouts,
              beds,
              seats,
              belts,
              fuel,
              levels,
              bedrooms,
              bathrooms,
              driver,
              mileage,
              captain,
              model,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        subcategories={subCategoryOptions}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
