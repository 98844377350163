import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { Button, Form, FieldCurrencyInput, FieldTextInput, ExternalLink } from '../../components';
import css from './EditListingDemageDepositForm.css';

export const EditListingDemageDepositFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <p className={css.message}>
            Damage deposits are optional. If you wish to require a damage deposit, please provide
            the amount below. As a Pairer, it is your sole responsibility to collect, hold, and
            return damage deposits.
          </p>

          <p className={css.question}>Do you require a damage deposit for your Pairable?</p>

          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}

          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <FieldCurrencyInput
            id="demageDeposit"
            name="demageDeposit"
            className={css.priceInput}
            autoFocus
            placeholder="If yes, how much?"
            currencyConfig={config.currencyConfig}
          />

          <br />

          <FieldTextInput
            id="paymentMethod"
            name="paymentMethod"
            className={css.title}
            type="text"
            placeholder="Preferred means of payment (ex: visa or cash at time of pickup)"
          />

          <br />
          <br />

          <div className={css.frame}>
            <p className={css.question}>Rent out your stuff with confidence!</p>

            <p className={css.message}>
              We've partnered with Duuo to provide you with optional on-demand damage protection
              insurance. Simply sign up now and let Duuo know when you get bookings!
            </p>

            <ExternalLink
              href="https://duuo.ca/rent-my-stuff-insurance/?utm_medium=website&utm_source=pairable&utm_campaign=rmspartnership"
              className={css.link}
            >
              Sign up & get covered!
            </ExternalLink>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDemageDepositFormComponent.defaultProps = { fetchErrors: null };

EditListingDemageDepositFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDemageDepositFormComponent);
