import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const pricingOptions =
    currentListing.attributes.publicData && currentListing.attributes.publicData.pricingOptions;

  const roundAndTruncate = number => {
    const truncatedNumber = (number - (number % 100)) / 100;
    const roundedNumber = (Math.ceil(truncatedNumber * 100) / 100).toFixed(2);
    return roundedNumber.replace(/\./g, '.');
  };

  const hourlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_hour'])}`;
  const hourlyTitle = 'ListingPage.perHour';
  const dailyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_day'])}`;
  const dailyTitle = 'ListingPage.perDay';
  const nightlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_night'])}`;
  const nightlyTitle = 'ListingPage.perNight';
  const weeklyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_week'])}`;
  const weeklyTitle = 'ListingPage.perWeek';
  const monthlyPrice = pricingOptions && `$${roundAndTruncate(pricingOptions['per_month'])}`;
  const monthlyTitle = 'ListingPage.perMonth';

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div>
        </div>
        <div className={css.price}>
          {pricingOptions && pricingOptions['per_hour'] ? (
            <div className={css.featuredListingPrice}>
              <div className={css.startValue}>
                <FormattedMessage id="FeaturedListings.startingPrice" />
              </div>
              <div className={css.priceValue}>{hourlyPrice}</div>
              <div className={css.titleValue}>
                &nbsp;
                <FormattedMessage id={hourlyTitle} />
              </div>
            </div>
          ) : null}
          {pricingOptions && pricingOptions['per_day'] && !pricingOptions['per_hour'] ? (
            <div className={css.featuredListingPrice}>
              <div className={css.startValue}>
                <FormattedMessage id="FeaturedListings.startingPrice" />
              </div>
              <div className={css.priceValue}>{dailyPrice}</div>
              <div className={css.titleValue}>
                &nbsp;
                <FormattedMessage id={dailyTitle} />
              </div>
            </div>
          ) : null}
          {pricingOptions && pricingOptions['per_night'] && !pricingOptions['per_hour'] ? (
            <div className={css.featuredListingPrice}>
              <div className={css.startValue}>
                <FormattedMessage id="FeaturedListings.startingPrice" />
              </div>
              <div className={css.priceValue}>{nightlyPrice}</div>
              <div className={css.titleValue}>
                &nbsp;
                <FormattedMessage id={nightlyTitle} />
              </div>
            </div>
          ) : null}
          {pricingOptions &&
          pricingOptions['per_week'] &&
          !pricingOptions['per_hour'] &&
          !pricingOptions['per_day'] &&
          !pricingOptions['per_night'] ? (
            <div className={css.featuredListingPrice}>
              <div className={css.startValue}>
                <FormattedMessage id="FeaturedListings.startingPrice" />
              </div>
              <div className={css.priceValue}>{weeklyPrice}</div>
              <div className={css.titleValue}>
                &nbsp;
                <FormattedMessage id={weeklyTitle} />
              </div>
            </div>
          ) : null}
          {pricingOptions &&
          pricingOptions['per_month'] &&
          !pricingOptions['per_week'] &&
          !pricingOptions['per_hour'] &&
          !pricingOptions['per_day'] &&
          !pricingOptions['per_night'] ? (
            <div className={css.featuredListingPrice}>
              <div className={css.startValue}>
                <FormattedMessage id="FeaturedListings.startingPrice" />
              </div>
              <div className={css.priceValue}>{monthlyPrice}</div>
              <div className={css.titleValue}>
                &nbsp;
                <FormattedMessage id={monthlyTitle} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
