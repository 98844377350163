import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.107,0c1.151,0 2.135,0.409 2.953,1.226c0.817,0.817 1.226,1.802 1.226,2.953l-0,13.928c-0,1.151 -0.409,2.135 -1.226,2.953c-0.818,0.817 -1.802,1.226 -2.953,1.226l-2.728,-0l0,-8.633l2.888,-0l0.435,-3.366l-3.323,-0l0,-2.147c0,-0.542 0.114,-0.948 0.341,-1.219c0.228,-0.271 0.67,-0.406 1.328,-0.406l1.77,-0.015l0,-3.003c-0.609,-0.087 -1.47,-0.131 -2.583,-0.131c-1.315,0 -2.367,0.387 -3.155,1.161c-0.789,0.774 -1.183,1.867 -1.183,3.279l0,2.481l-2.901,-0l-0,3.366l2.901,-0l0,8.633l-7.718,-0c-1.151,-0 -2.136,-0.409 -2.953,-1.226c-0.817,-0.818 -1.226,-1.802 -1.226,-2.953l0,-13.928c0,-1.151 0.409,-2.136 1.226,-2.953c0.817,-0.817 1.802,-1.226 2.953,-1.226l13.928,0Z" />
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
