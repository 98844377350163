import React from 'react';
import { FieldSelect } from '../../components';
import includes from 'lodash/includes';
import { Field } from 'react-final-form';
import { Creatable } from 'react-select';

import css from './EditListingDescriptionForm.css';

const CustomTagMultiselectFieldMaybe = props => {
  if (typeof window === 'undefined') {
    return null;
  }

  const { default: Select, components } = require('react-select'); // eslint-disable-line global-require
  const { name, id, intl } = props;
  const tagLabel =
    'Provide some keywords to help customers find your Pairable. For example: Kayaks, summer, fun, ocean, beach day, adventure';

  return (
    <div>
      <p>{tagLabel}</p>
      <Field name={name} id={id} label={tagLabel}>
        {({ input, meta }) => {
          return (
            <Creatable
              closeMenuOnSelect={false}
              defaultValue={meta.initial}
              isMulti
              onChange={input.onChange}
              options={[]}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default CustomTagMultiselectFieldMaybe;
