import React from 'react';
import { FieldTextInput } from '../../components';
import CustomOptionsSelectFieldMaybe from './CustomOptionsSelectFieldMaybe';
import CustomVariantsSelectFieldMaybe from './CustomVariantsSelectFieldMaybe';
import CustomTagMultiselectFieldMaybe from './CustomTagMultiselectFieldMaybe';
import { maxLength, required, composeValidators } from '../../util/validators';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const RvsAndCampersFields = ({ intl }) => {
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );

  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

  const rvsAndCampersTitleMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersTitle',
  });
  const rvsAndCampersTitlePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersTitlePlaceholder',
  });
  const rvsAndCampersTitleRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersTitleRequired',
  });

  const rvsAndCampersHeadlineMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersHeadline',
  });
  const rvsAndCampersHeadlinePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersHeadlinePlaceholder',
  });
  const rvsAndCampersHeadlineRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersHeadlineRequired',
  });

  const rvsAndCampersDescriptionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDescription',
  });
  const rvsAndCampersDescriptionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDescriptionPlaceholder',
  });
  const rvsAndCampersDescriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDescriptionRequired',
  });

  const rvsAndCampersAccidentMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersAccident',
  });
  const rvsAndCampersAccidentPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersAccidentPlaceholder',
  });
  const rvsAndCampersAccidentRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersAccidentRequired',
  });

  const rvsAndCampersDriverMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDriver',
  });
  const rvsAndCampersDriverPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDriverPlaceholder',
  });
  const rvsAndCampersDriverRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDriverRequired',
  });

  const rvsAndCampersQuantityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersQuantity',
  });
  const rvsAndCampersQuantityPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersQuantityPlaceholder',
  });
  const rvsAndCampersQuantityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersQuantityRequired',
  });

  const rvsAndCampersConditionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersCondition',
  });
  const rvsAndCampersConditionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersConditionPlaceholder',
  });
  const rvsAndCampersConditionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersConditionRequired',
  });

  const rvsAndCampersDemonstrationMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDemonstration',
  });
  const rvsAndCampersDemonstrationPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDemonstrationPlaceholder',
  });
  const rvsAndCampersDemonstrationRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersDemonstrationRequired',
  });

  const rvsAndCampersLicenseMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLicense',
  });
  const rvsAndCampersLicensePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLicensePlaceholder',
  });
  const rvsAndCampersLicenseRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLicenseRequired',
  });

  const rvsAndCampersLengthMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLength',
  });
  const rvsAndCampersLengthPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLengthPlaceholder',
  });
  const rvsAndCampersLengthRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLengthRequired',
  });

  const rvsAndCampersTransmissionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersTransmission',
  });
  const rvsAndCampersTransmissionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersTransmissionPlaceholder',
  });
  const rvsAndCampersTransmissionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersTransmissionRequired',
  });

  const rvsAndCampersCapacityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersCapacity',
  });
  const rvsAndCampersCapacityPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersCapacityPlaceholder',
  });
  const rvsAndCampersCapacityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersCapacityRequired',
  });

  const rvsAndCampersSlideoutsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersSlideouts',
  });
  const rvsAndCampersSlideoutsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersSlideoutsPlaceholder',
  });
  const rvsAndCampersSlideoutsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersSlideoutsRequired',
  });

  const rvsAndCampersBedsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersBeds',
  });
  const rvsAndCampersBedsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersBedsPlaceholder',
  });
  const rvsAndCampersBedsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersBedsRequired',
  });

  const rvsAndCampersSeatsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersSeats',
  });
  const rvsAndCampersSeatsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersSeatsPlaceholder',
  });
  const rvsAndCampersSeatsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersSeatsRequired',
  });

  const rvsAndCampersBeltsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersBelts',
  });
  const rvsAndCampersBeltsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersBeltsPlaceholder',
  });
  const rvsAndCampersBeltsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersBeltsRequired',
  });

  const rvsAndCampersModelMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersModel',
  });
  const rvsAndCampersModelPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersModelPlaceholder',
  });
  const rvsAndCampersModelRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersModelRequired',
  });

  const rvsAndCampersClassMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersClass',
  });
  const rvsAndCampersClassPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersClassPlaceholder',
  });
  const rvsAndCampersClassRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersClassRequired',
  });

  const rvsAndCampersFuelMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersFuel',
  });
  const rvsAndCampersFuelPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersFuelPlaceholder',
  });
  const rvsAndCampersFuelRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersFuelRequired',
  });

  const rvsAndCampersLevelsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLevels',
  });
  const rvsAndCampersLevelsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLevelsPlaceholder',
  });
  const rvsAndCampersLevelsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersLevelsRequired',
  });

  const rvsAndCampersYearMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersYear',
  });
  const rvsAndCampersYearPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersYearPlaceholder',
  });
  const rvsAndCampersYearRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.rvsAndCampersYearRequired',
  });

  return (
    <>
      <FieldTextInput
        id="title"
        name="title"
        className={css.title}
        type="text"
        label={rvsAndCampersTitleMessage}
        placeholder={rvsAndCampersTitlePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(rvsAndCampersTitleRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="headline"
        name="headline"
        className={css.title}
        type="text"
        label={rvsAndCampersHeadlineMessage}
        placeholder={rvsAndCampersHeadlinePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(rvsAndCampersHeadlineRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="description"
        name="description"
        className={css.description}
        type="textarea"
        label={rvsAndCampersDescriptionMessage}
        placeholder={rvsAndCampersDescriptionPlaceholderMessage}
        rows="3"
        validate={composeValidators(required(rvsAndCampersDescriptionRequiredMessage))}
      />

      <CustomTagMultiselectFieldMaybe id="tags" name="tags" intl={intl} />

      <FieldTextInput
        id="accident"
        name="accident"
        className={css.description}
        type="text"
        label={rvsAndCampersAccidentMessage}
        placeholder={rvsAndCampersAccidentPlaceholderMessage}
      />

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="quantity"
          name="quantity"
          className={css.firstColRoot}
          type="number"
          label={rvsAndCampersQuantityMessage}
          placeholder={rvsAndCampersQuantityPlaceholderMessage}
          defaultValue="1"
        />
        <CustomVariantsSelectFieldMaybe
          id="condition"
          name="condition"
          variants={['New', 'Almost new', 'Some wear and tear', "Don't judge"]}
          intl={intl}
          label={rvsAndCampersConditionMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="demonstration"
          name="demonstration"
          options={['Yes', 'No']}
          intl={intl}
          label={rvsAndCampersDemonstrationMessage}
        />
        <CustomOptionsSelectFieldMaybe
          id="license"
          name="license"
          options={['Yes', 'No']}
          intl={intl}
          label={rvsAndCampersLicenseMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="length"
          name="length"
          className={css.firstColRoot}
          type="number"
          label={rvsAndCampersLengthMessage}
          placeholder={rvsAndCampersLengthPlaceholderMessage}
        />
        <FieldTextInput
          id="transmission"
          name="transmission"
          className={css.firstColRoot}
          type="text"
          label={rvsAndCampersTransmissionMessage}
          placeholder={rvsAndCampersTransmissionPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="capacity"
          name="capacity"
          className={css.firstColRoot}
          type="number"
          label={rvsAndCampersCapacityMessage}
          placeholder={rvsAndCampersCapacityPlaceholderMessage}
        />
        <FieldTextInput
          id="slideouts"
          name="slideouts"
          className={css.firstColRoot}
          type="number"
          min="1"
          label={rvsAndCampersSlideoutsMessage}
          placeholder={rvsAndCampersSlideoutsPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="beds"
          name="beds"
          className={css.firstColRoot}
          type="number"
          min="1"
          label={rvsAndCampersBedsMessage}
          placeholder={rvsAndCampersBedsPlaceholderMessage}
        />
        <FieldTextInput
          id="seats"
          name="seats"
          className={css.firstColRoot}
          type="number"
          label={rvsAndCampersSeatsMessage}
          placeholder={rvsAndCampersSeatsPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="belts"
          name="belts"
          className={css.firstColRoot}
          type="number"
          label={rvsAndCampersBeltsMessage}
          placeholder={rvsAndCampersBeltsPlaceholderMessage}
        />
        <FieldTextInput
          id="fuel"
          name="fuel"
          className={css.firstColRoot}
          type="text"
          label={rvsAndCampersFuelMessage}
          placeholder={rvsAndCampersFuelPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="levels"
          name="levels"
          options={['Yes', 'No']}
          intl={intl}
          label={rvsAndCampersLevelsMessage}
        />
        <FieldTextInput
          id="year"
          name="year"
          className={css.firstColRoot}
          type="number"
          label={rvsAndCampersYearMessage}
          placeholder={rvsAndCampersYearPlaceholderMessage}
        />
      </div>
    </>
  );
};

export default RvsAndCampersFields;
