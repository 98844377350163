import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { SectionHeroSearchForm } from '../../forms';
import { Button, NamedLink } from '../../components';

import css from './SectionHero.css';
import logo from './hero-logo.svg';

const SectionHero = props => {
  const {
    rootClassName,
    className,
    history,
    categoriesRef,
    isMobileLayout,
    handleScrollToCategories,
    handleScrollToFeatured,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.titleAlt" />
        </h2>
        {isMobileLayout ? (
          <SectionHeroSearchForm
            className={css.searchbar}
            desktopInputRoot={css.heroSearch}
            withButton={true}
            history={history}
          />
        ) : (
          <div className={css.heroButtons}>
            <Button className={css.heroButton} onClick={handleScrollToCategories}>
              <FormattedMessage id="SectionHero.viewCategories" />
            </Button>
            <NamedLink className={css.heroButton} name="SearchPage">
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
            <Button className={css.heroButton} onClick={handleScrollToFeatured}>
              <FormattedMessage id="SectionHero.featuredButton" />
            </Button>
            <a href="https://blog.pairables.ca/" className={css.heroButton}>
              How it works
            </a>
          </div>
        )}
      </div>
      {isMobileLayout ? null : <div className={css.scrollTarget} ref={categoriesRef}></div>}
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
