import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CustomOptionsSelectFieldMaybe = props => {
  const { name, id, options, intl, label } = props;
  return options ? (
    <FieldSelect className={css.firstColRoot} name={name} id={id} label={label}>
      <option disabled value="">
        {'Yes or No'}
      </option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomOptionsSelectFieldMaybe;
