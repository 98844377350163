import React from 'react';
import { FieldTextInput } from '../../components';
import CustomTagMultiselectFieldMaybe from './CustomTagMultiselectFieldMaybe';
import { maxLength, required, composeValidators } from '../../util/validators';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const VacationPropertiesFields = ({ intl }) => {
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );

  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

  const vacationPropertiesTitleMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesTitle',
  });
  const vacationPropertiesTitlePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesTitlePlaceholder',
  });
  const vacationPropertiesTitleRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesTitleRequired',
  });

  const vacationPropertiesHeadlineMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesHeadline',
  });
  const vacationPropertiesHeadlinePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesHeadlinePlaceholder',
  });
  const vacationPropertiesHeadlineRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesHeadlineRequired',
  });

  const vacationPropertiesDescriptionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.description',
  });
  const vacationPropertiesDescriptionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.descriptionPlaceholder',
  });
  const vacationPropertiesDescriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesDescriptionRequired',
  });

  const vacationPropertiesCapacityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesCapacity',
  });
  const vacationPropertiesCapacityPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesCapacityPlaceholder',
  });
  const vacationPropertiesCapacityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesCapacityRequired',
  });

  const vacationPropertiesBedsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBeds',
  });
  const vacationPropertiesBedsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBedsPlaceholder',
  });
  const vacationPropertiesBedsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBedsRequired',
  });

  const vacationPropertiesBedroomsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBedrooms',
  });
  const vacationPropertiesBedroomsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBedroomsPlaceholder',
  });
  const vacationPropertiesBedroomsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBedroomsRequired',
  });

  const vacationPropertiesBathroomsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBathrooms',
  });
  const vacationPropertiesBathroomsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBathroomsPlaceholder',
  });
  const vacationPropertiesBathroomsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vacationPropertiesBathroomsRequired',
  });

  return (
    <>
      <FieldTextInput
        id="title"
        name="title"
        className={css.title}
        type="text"
        label={vacationPropertiesTitleMessage}
        placeholder={vacationPropertiesTitlePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(vacationPropertiesTitleRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="headline"
        name="headline"
        className={css.title}
        type="text"
        label={vacationPropertiesHeadlineMessage}
        placeholder={vacationPropertiesHeadlinePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(vacationPropertiesHeadlineRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="description"
        name="description"
        className={css.description}
        type="textarea"
        label={vacationPropertiesDescriptionMessage}
        placeholder={vacationPropertiesDescriptionPlaceholderMessage}
        rows="3"
        validate={composeValidators(required(vacationPropertiesDescriptionRequiredMessage))}
      />

      <CustomTagMultiselectFieldMaybe id="tags" name="tags" intl={intl} />

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="capacity"
          name="capacity"
          className={css.firstColRoot}
          type="number"
          min="1"
          label={vacationPropertiesCapacityMessage}
          placeholder={vacationPropertiesCapacityPlaceholderMessage}
        />
        <FieldTextInput
          id="beds"
          name="beds"
          className={css.firstColRoot}
          type="number"
          min="1"
          label={vacationPropertiesBedsMessage}
          placeholder={vacationPropertiesBedsPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="bedrooms"
          name="bedrooms"
          className={css.firstColRoot}
          type="number"
          min="1"
          label={vacationPropertiesBedroomsMessage}
          placeholder={vacationPropertiesBedroomsPlaceholderMessage}
        />
        <FieldTextInput
          id="bathrooms"
          name="bathrooms"
          className={css.firstColRoot}
          type="number"
          min="1"
          label={vacationPropertiesBathroomsMessage}
          placeholder={vacationPropertiesBathroomsPlaceholderMessage}
        />
      </div>
    </>
  );
};

export default VacationPropertiesFields;
