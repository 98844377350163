import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListingPage.css';

const SectionCancellationPoliciesMaybe = props => {
  const { className, rootClassName, publicData } = props;
  const classes = classNames(rootClassName || css.root, className);

  const firstPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.firstPolicyTitle" />;
  const firstPolicyNote = <FormattedMessage id="EditListingPoliciesForm.firstPolicyNote" />;
  const secondPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.secondPolicyTitle" />;
  const secondPolicyNote = <FormattedMessage id="EditListingPoliciesForm.secondPolicyNote" />;
  const thirdPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.thirdPolicyTitle" />;
  const thirdPolicyNote = <FormattedMessage id="EditListingPoliciesForm.thirdPolicyNote" />;
  const fourthPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.fourthPolicyTitle" />;
  const fourthPolicyNote = <FormattedMessage id="EditListingPoliciesForm.fourthPolicyNote" />;

  const cancellationPolicy = () => {
    if (publicData.cancellationPolicy === '1') {
      return (
        <div>
          <p className={css.subTitle}>{firstPolicyTitle}</p>
          <p className={css.description}>{firstPolicyNote}</p>
        </div>
      );
    } else if (publicData.cancellationPolicy === '2') {
      return (
        <div>
          <p className={css.subTitle}>{secondPolicyTitle}</p>
          <p className={css.description}>{secondPolicyNote}</p>
        </div>
      );
    } else if (publicData.cancellationPolicy === '3') {
      return (
        <div>
          <p className={css.subTitle}>{thirdPolicyTitle}</p>
          <p className={css.description}>{thirdPolicyNote}</p>
        </div>
      );
    } else if (publicData.cancellationPolicy === '4') {
      return (
        <div>
          <p className={css.subTitle}>{fourthPolicyTitle}</p>
          <p className={css.description}>{fourthPolicyNote}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className={css.description}>{publicData.cancellationPolicy}</p>
        </div>
      );
    }
  };

  return publicData && publicData.cancellationPolicy ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.cancellationPoliciesTitle" />
      </h2>
      {cancellationPolicy()}
    </div>
  ) : null;
};

SectionCancellationPoliciesMaybe.defaultProps = { className: null, rootClassName: null };

SectionCancellationPoliciesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    cancellationPolicy: string,
  }),
};

export default SectionCancellationPoliciesMaybe;
