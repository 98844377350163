import React from 'react';
import { FieldTextInput } from '../../components';
import CustomOptionsSelectFieldMaybe from './CustomOptionsSelectFieldMaybe';
import CustomVariantsSelectFieldMaybe from './CustomVariantsSelectFieldMaybe';
import CustomTagMultiselectFieldMaybe from './CustomTagMultiselectFieldMaybe';
import { maxLength, required, composeValidators } from '../../util/validators';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const WatercraftsFields = ({ intl }) => {
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );

  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

  const watercraftsTitleMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsTitle',
  });
  const watercraftsTitlePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsTitlePlaceholder',
  });
  const watercraftsTitleRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsTitleRequired',
  });

  const watercraftsHeadlineMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsHeadline',
  });
  const watercraftsHeadlinePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsHeadlinePlaceholder',
  });
  const watercraftsHeadlineRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsHeadlineRequired',
  });

  const watercraftsDescriptionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsDescription',
  });
  const watercraftsDescriptionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsDescriptionPlaceholder',
  });
  const watercraftsDescriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsDescriptionRequired',
  });

  const watercraftsCaptainMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsCaptain',
  });
  const watercraftsCaptainPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsCaptainPlaceholder',
  });
  const watercraftsCaptainRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsCaptainRequired',
  });

  const watercraftsQuantityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsQuantity',
  });
  const watercraftsQuantityPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsQuantityPlaceholder',
  });
  const watercraftsQuantityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsQuantityRequired',
  });

  const watercraftsConditionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsCondition',
  });
  const watercraftsConditionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsConditionPlaceholder',
  });
  const watercraftsConditionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsConditionRequired',
  });

  const watercraftsDemonstrationMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsDemonstration',
  });
  const watercraftsDemonstrationPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsDemonstrationPlaceholder',
  });
  const watercraftsDemonstrationRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsDemonstrationRequired',
  });

  const watercraftsLicenseMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLicense',
  });
  const watercraftsLicensePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLicensePlaceholder',
  });
  const watercraftsLicenseRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLicenseRequired',
  });

  const watercraftsLengthMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLength',
  });
  const watercraftsLengthPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLengthPlaceholder',
  });
  const watercraftsLengthRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLengthRequired',
  });

  const watercraftsGuestsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsGuests',
  });
  const watercraftsGuestsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsGuestsPlaceholder',
  });
  const watercraftsGuestsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsGuestsRequired',
  });

  const watercraftsModelMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsModel',
  });
  const watercraftsModelPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsModelPlaceholder',
  });
  const watercraftsModelRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsModelRequired',
  });

  const watercraftsFuelMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsFuel',
  });
  const watercraftsFuelPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsFuelPlaceholder',
  });
  const watercraftsFuelRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsFuelRequired',
  });

  const watercraftsLevelsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLevels',
  });
  const watercraftsLevelsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLevelsPlaceholder',
  });
  const watercraftsLevelsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsLevelsRequired',
  });

  const watercraftsYearMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsYear',
  });
  const watercraftsYearPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsYearPlaceholder',
  });
  const watercraftsYearRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.watercraftsYearRequired',
  });

  return (
    <>
      <FieldTextInput
        id="title"
        name="title"
        className={css.title}
        type="text"
        label={watercraftsTitleMessage}
        placeholder={watercraftsTitlePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(required(watercraftsTitleRequiredMessage), maxLength60Message)}
        autoFocus
      />

      <FieldTextInput
        id="headline"
        name="headline"
        className={css.title}
        type="text"
        label={watercraftsHeadlineMessage}
        placeholder={watercraftsHeadlinePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(
          required(watercraftsHeadlineRequiredMessage),
          maxLength60Message
        )}
        autoFocus
      />

      <FieldTextInput
        id="description"
        name="description"
        className={css.description}
        type="textarea"
        label={watercraftsDescriptionMessage}
        placeholder={watercraftsDescriptionPlaceholderMessage}
        rows="3"
        validate={composeValidators(required(watercraftsDescriptionRequiredMessage))}
      />

      <CustomTagMultiselectFieldMaybe id="tags" name="tags" intl={intl} />

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="captain"
          name="captain"
          options={['Yes', 'No']}
          intl={intl}
          label={watercraftsCaptainMessage}
        />
        <FieldTextInput
          id="quantity"
          name="quantity"
          className={css.firstColRoot}
          type="number"
          label={watercraftsQuantityMessage}
          placeholder={watercraftsQuantityPlaceholderMessage}
          defaultValue="1"
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomVariantsSelectFieldMaybe
          id="condition"
          name="condition"
          variants={['New', 'Almost new', 'Some wear and tear', "Don't judge"]}
          intl={intl}
          label={watercraftsConditionMessage}
        />
        <CustomOptionsSelectFieldMaybe
          id="demonstration"
          name="demonstration"
          options={['Yes', 'No']}
          intl={intl}
          label={watercraftsDemonstrationMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="license"
          name="license"
          options={['Yes', 'No']}
          intl={intl}
          label={watercraftsLicenseMessage}
        />
        <FieldTextInput
          id="length"
          name="length"
          className={css.firstColRoot}
          type="number"
          label={watercraftsLengthMessage}
          placeholder={watercraftsLengthPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="guests"
          name="guests"
          className={css.firstColRoot}
          type="number"
          label={watercraftsGuestsMessage}
          placeholder={watercraftsGuestsPlaceholderMessage}
        />
        <FieldTextInput
          id="model"
          name="model"
          className={css.firstColRoot}
          type="text"
          label={watercraftsModelMessage}
          placeholder={watercraftsModelPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="fuel"
          name="fuel"
          className={css.firstColRoot}
          type="text"
          label={watercraftsFuelMessage}
          placeholder={watercraftsFuelPlaceholderMessage}
        />
        <CustomOptionsSelectFieldMaybe
          id="levels"
          name="levels"
          options={['Yes', 'No']}
          intl={intl}
          label={watercraftsLevelsMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="year"
          name="year"
          className={css.firstColRoot}
          type="number"
          label={watercraftsYearMessage}
          placeholder={watercraftsYearPlaceholderMessage}
        />
      </div>
    </>
  );
};

export default WatercraftsFields;
