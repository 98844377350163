import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Logo,
  Footer,
} from '../../components';

import css from './AboutPage.css';

import coverImage from './about-header-image.jpg';

const AboutPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Our mission – Do more, buy less',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.headerWrapper}>
            <div className={css.coverImage}>
              <img src={coverImage} />
            </div>
            <div className={css.headerContent}>
              <Logo format="white" />
              <h2>
                <FormattedMessage id="Site.tagline" />
              </h2>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.pageTitle}>
              <h1 className={css.title}>
                <FormattedMessage id="AboutPage.title" />
              </h1>
              <h2 className={css.subtitle}>
                <FormattedMessage id="AboutPage.subtitle" />
              </h2>
            </div>
            <div className={css.contentMain}>
              <FormattedHTMLMessage id="AboutPage.content" />
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
