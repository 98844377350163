import React from 'react';
import { FieldTextInput } from '../../components';
import CustomOptionsSelectFieldMaybe from './CustomOptionsSelectFieldMaybe';
import CustomVariantsSelectFieldMaybe from './CustomVariantsSelectFieldMaybe';
import CustomTagMultiselectFieldMaybe from './CustomTagMultiselectFieldMaybe';
import { maxLength, required, composeValidators } from '../../util/validators';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const VehiclesFields = ({ intl }) => {
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );

  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

  const vehiclesTitleMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesTitle',
  });
  const vehiclesTitlePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesTitlePlaceholder',
  });
  const vehiclesTitleRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesTitleRequired',
  });

  const vehiclesHeadlineMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesHeadline',
  });
  const vehiclesHeadlinePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesHeadlinePlaceholder',
  });
  const vehiclesHeadlineRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesHeadlineRequired',
  });

  const vehiclesDescriptionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDescription',
  });
  const vehiclesDescriptionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDescriptionPlaceholder',
  });
  const vehiclesDescriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDescriptionRequired',
  });

  const vehiclesAccidentMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesAccident',
  });
  const vehiclesAccidentPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesAccidentPlaceholder',
  });
  const vehiclesAccidentRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesAccidentRequired',
  });

  const vehiclesSeatsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesSeats',
  });
  const vehiclesSeatsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesSeatsPlaceholder',
  });
  const vehiclesSeatsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesSeatsRequired',
  });

  const vehiclesBeltsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesBelts',
  });
  const vehiclesBeltsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesBeltsPlaceholder',
  });
  const vehiclesBeltsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesBeltsRequired',
  });

  const vehiclesDriverMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDriver',
  });
  const vehiclesDriverPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDriverPlaceholder',
  });
  const vehiclesDriverRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDriverRequired',
  });

  const vehiclesQuantityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesQuantity',
  });
  const vehiclesQuantityPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesQuantityPlaceholder',
  });
  const vehiclesQuantityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesQuantityRequired',
  });

  const vehiclesConditionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesCondition',
  });
  const vehiclesConditionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesConditionPlaceholder',
  });
  const vehiclesConditionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesConditionRequired',
  });

  const vehiclesDemonstrationMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDemonstration',
  });
  const vehiclesDemonstrationPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDemonstrationPlaceholder',
  });
  const vehiclesDemonstrationRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesDemonstrationRequired',
  });

  const vehiclesLicenseMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesLicense',
  });
  const vehiclesLicensePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesLicensePlaceholder',
  });
  const vehiclesLicenseRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesLicenseRequired',
  });

  const vehiclesLevelsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesLevels',
  });
  const vehiclesLevelsPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesLevelsPlaceholder',
  });
  const vehiclesLevelsRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesLevelsRequired',
  });

  const vehiclesMileageMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesMileage',
  });
  const vehiclesMileagePlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesMileagePlaceholder',
  });
  const vehiclesMileageRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesMileageRequired',
  });

  const vehiclesFuelMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesFuel',
  });
  const vehiclesFuelPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesFuelPlaceholder',
  });
  const vehiclesFuelRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesFuelRequired',
  });

  const vehiclesTransmissionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesTransmission',
  });
  const vehiclesTransmissionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesTransmissionPlaceholder',
  });
  const vehiclesTransmissionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesTransmissionRequired',
  });

  const vehiclesYearMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesYear',
  });
  const vehiclesYearPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesYearPlaceholder',
  });
  const vehiclesYearRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.vehiclesYearRequired',
  });

  return (
    <>
      <FieldTextInput
        id="title"
        name="title"
        className={css.title}
        type="text"
        label={vehiclesTitleMessage}
        placeholder={vehiclesTitlePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(required(vehiclesTitleRequiredMessage), maxLength60Message)}
        autoFocus
      />

      <FieldTextInput
        id="headline"
        name="headline"
        className={css.title}
        type="text"
        label={vehiclesHeadlineMessage}
        placeholder={vehiclesHeadlinePlaceholderMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={composeValidators(required(vehiclesHeadlineRequiredMessage), maxLength60Message)}
        autoFocus
      />

      <FieldTextInput
        id="description"
        name="description"
        className={css.description}
        type="textarea"
        label={vehiclesDescriptionMessage}
        placeholder={vehiclesDescriptionPlaceholderMessage}
        rows="3"
        validate={composeValidators(required(vehiclesDescriptionRequiredMessage))}
      />

      <CustomTagMultiselectFieldMaybe id="tags" name="tags" intl={intl} />

      <FieldTextInput
        id="accident"
        name="accident"
        className={css.description}
        type="text"
        label={vehiclesAccidentMessage}
        placeholder={vehiclesAccidentPlaceholderMessage}
      />

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="seats"
          name="seats"
          className={css.firstColRoot}
          type="number"
          label={vehiclesSeatsMessage}
          placeholder={vehiclesSeatsPlaceholderMessage}
        />
        <FieldTextInput
          id="belts"
          name="belts"
          className={css.firstColRoot}
          type="number"
          label={vehiclesBeltsMessage}
          placeholder={vehiclesBeltsPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="driver"
          name="driver"
          options={['Yes', 'No']}
          intl={intl}
          label={vehiclesDriverMessage}
        />
        <FieldTextInput
          id="quantity"
          name="quantity"
          className={css.firstColRoot}
          type="number"
          label={vehiclesQuantityMessage}
          placeholder={vehiclesQuantityPlaceholderMessage}
          defaultValue="1"
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomVariantsSelectFieldMaybe
          id="condition"
          name="condition"
          variants={['New', 'Almost new', 'Some wear and tear', "Don't judge"]}
          intl={intl}
          label={vehiclesConditionMessage}
        />
        <CustomOptionsSelectFieldMaybe
          id="demonstration"
          name="demonstration"
          options={['Yes', 'No']}
          intl={intl}
          label={vehiclesDemonstrationMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <CustomOptionsSelectFieldMaybe
          id="license"
          name="license"
          options={['Yes', 'No']}
          intl={intl}
          label={vehiclesLicenseMessage}
        />
        <CustomOptionsSelectFieldMaybe
          id="levels"
          name="levels"
          options={['Yes', 'No']}
          intl={intl}
          label={vehiclesLevelsMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="mileage"
          name="mileage"
          className={css.firstColRoot}
          type="text"
          label={vehiclesMileageMessage}
          placeholder={vehiclesMileagePlaceholderMessage}
        />
        <FieldTextInput
          id="fuel"
          name="fuel"
          className={css.firstColRoot}
          type="text"
          label={vehiclesFuelMessage}
          placeholder={vehiclesFuelPlaceholderMessage}
        />
      </div>

      <div className={css.propertiesRow}>
        <FieldTextInput
          id="transmission"
          name="transmission"
          className={css.firstColRoot}
          type="text"
          label={vehiclesTransmissionMessage}
          placeholder={vehiclesTransmissionPlaceholderMessage}
        />
        <FieldTextInput
          id="year"
          name="year"
          className={css.firstColRoot}
          type="number"
          label={vehiclesYearMessage}
          placeholder={vehiclesYearPlaceholderMessage}
        />
      </div>
    </>
  );
};

export default VehiclesFields;
