import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { ensureOwnListing } from '../../util/data';
import { EditListingCancellationPolicyForm } from '../../forms';

import css from './EditListingCancellationPolicyPanel.css';

const EditListingCancellationPolicyPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const stripeConnected = (currentUser && currentUser.attributes.stripeConnected) || null;
  const isVerified = stripeConnected == true;
  const { publicData } = currentListing.attributes;
  const isFareharbor =
    currentListing.id &&
    currentListing.author &&
    currentListing.author.attributes.profile.publicData &&
    currentListing.author.attributes.profile.publicData.fareharbor;

  // const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  // const panelTitle = isPublished ? (
  //   <FormattedMessage
  //     id="EditListingPoliciesPanel.title"
  //     values={{ listingTitle: <ListingLink listing={listing} /> }}
  //   />
  // ) : (
  //   <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  // );

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCancellationPolicyPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCancellationPolicyPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingCancellationPolicyForm
        className={css.form}
        publicData={publicData}
        initialValues={{ cancellationPolicy: publicData.cancellationPolicy || '1' }}
        onSubmit={values => {
          const { cancellationPolicy } = values;
          const updateValues = {
            publicData: {
              cancellationPolicy,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        isPublished={isPublished}
        isVerified={isVerified}
        isFareharbor={isFareharbor}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingCancellationPolicyPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingCancellationPolicyPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCancellationPolicyPanel;
