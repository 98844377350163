import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemTermAndTimeMaybe = props => {
  const { transaction, unitType, userRole, intl } = props;

  const amount = transaction.attributes.metadata && transaction.attributes.metadata.amount;
  const term = transaction.attributes.metadata && transaction.attributes.metadata.term;
  const time = transaction.attributes.metadata && transaction.attributes.metadata.time;
  const period = transaction.attributes.metadata && transaction.attributes.metadata.period;
  const showTermAndTime = term && time && period;

  return showTermAndTime ? (
    <>
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.amount" />
        </span>
        <span className={css.itemValue}>{amount}</span>
      </div>
      {term > 24 ? null : (
        <div className={css.subTotalLineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.term" />
          </span>
          <span className={css.itemValue}>{term} hours</span>
        </div>
      )}
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.time" />
        </span>
        <span className={css.itemValue}>
          {time} {period}
        </span>
      </div>
      <hr className={css.totalDivider} />
    </>
  ) : null;
};

LineItemTermAndTimeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTermAndTimeMaybe;
