import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
      <strong>Pairables Marketplace Ltd. Terms of Use Agreement</strong>
      </p>
      <p>
      Last updated:  April 01, 2021
      </p>
      <p>
      Thanks for reading!
      </p>
      <p>
      - from the Pairables Team
      </p>
      <p>
      PAIRABLES MARKETPLACE LTD. <strong>(WHICH MAY ALSO BE REFERRERED TO AS ”COMPANY”/”WE”/”US”/”OUR”</strong>) PROVIDES THE “<strong>PLATFORM”</strong> LOCATED AT<a href="http://WWW.PAIRABLES.CA/"> WWW.PAIRABLES.CA</a> TO YOU SUBJECT TO THE FOLLOWING TERMS OF USE AGREEMENT <strong>(“AGREEMENT”) </strong>AND OUR PRIVACY POLICY <strong>(COLLECTIVELY “TERMS”)</strong>. YOU ARE A <strong>“USER” </strong>BY USING THE PLATFORM IN ANY WAY AND IT IS YOUR RESPONSIBILITY TO READ AND UNDERSTAND THE TERMS PRIOR TO USING THE PLATFORM. WE MAY MAKE MODIFICATIONS OR CHANGES TO OUR TERMS AT ANY TIME, EFFECTIVE BY POSTING NEW TERMS ON THE PLATFORM WITH THE DATE OF THE LAST UPDATE. IF YOU DO NOT AGREE WITH THE TERMS, PLEASE DO NOT USE THE PLATFORM. IF YOU ARE ACTING ON BEHALF OF ANOTHER PERSON, BUSINESS, CORPORATION OR ENTITY<strong> (COLLECTIVELY “ENTITY”)</strong>, <strong>“YOU” </strong>MEANS THE ENTITY AND THE ENTITY HAS GIVEN YOU PERMISSION AND AUTHORIZATION TO ACT ON BEHALF OF SUCH ENTITY.
      </p>
      <p>
      PLEASE CONTACT US IF YOU HAVE ANY QUESTIONS ABOUT THE TERMS OR OUR PLATFORM VIA THE CHAT WIDGET ON OUR WEBSITE OR BY EMAILING INFO@PAIRABLES.CA.
      </p>
      <p>
          What We do and our relationship with You
      </p>
      <p>
          1.1 The Platform
      </p>
      <p>
          1.2 Transactions
      </p>
      <p>
          1.3 Booking and Posting a Pairable
      </p>
      <p>
          1.4 Disputes & Issues between Members
      </p>
      <p>
          1.5 Separate agreements
      </p>
      <p>
          1.6 Insurance and Third Party Websites
      </p>
      <p>
          User’s Content
      </p>
      <p>
          2.1 Creating an Account
      </p>
      <p>
          2.2 Posting Procedures (Posting a Pairable)
      </p>
      <p>
          2.3 Booking Procedures (Booking a Pairable)
      </p>
      <p>
          2.4 Pickup and Delivery
      </p>
      <p>
          2.5 Conditions of Use
      </p>
      <p>
          2.6 User Fees
      </p>
      <p>
          2.7 Taxes
      </p>
      <p>
          2.8 Refunds & Cancellation Policies
      </p>
      <p>
          2.9 Damage Deposits
      </p>
      <p>
          2.10 Payouts
      </p>
      <p>
          2.11 Late Fees
      </p>
      <p>
          2.12 Reviews & Ratings
      </p>
      <p>
          Disclaimer of Warranty and Limitation of Liability
      </p>
      <p>
          Governing Law
      </p>
      <p>
          Licence To Use This Website
      </p>
      <p>
          Contact Us
      </p>
      <h2><strong>What We do and our relationship with You</strong></h2>

      <h2><strong>1.1 The Platform</strong></h2>

      <p>
      Our Platform allows registered Users <strong>(“Members”) </strong>to:
      </p>

      <ol>

      <li>Advertise items, services, or experiences <strong>(collectively “Pairable(s)”)</strong> that are available for hire, rent, lease or charter <strong>(collectively “Rent”) </strong>by publishing an advertisement <strong>(“Listing”) </strong>on the Platform.</li>

      <li>Communicate directly with other Members and Users regarding booking a Pairable</li>

      <li>Access services that help with your personal or business booking procedures such as to complete transactions through a third-party processing provider.</li>

      </ol>

      <p>
      Members who offer Pairables for Rent <strong>(“Pairers”)</strong> are solely responsible for their Listing(s), Pairable(s), Booking(s) and Client(s). When Users request or accept a booking, they are entering into a contract directly with each other. Pairables Marketplace Ltd. is not and does not become a party to any rental agreement, other transaction, or contractual relationship between Users aside from what is set forth in these Terms.
      </p>
      <h2><strong>1.2 Transactions</strong></h2>

      <p>
      Pairables Marketplace Ltd. may handle transactions through its third-party Stripe. Pairers, and Pairers alone are responsible for understanding the Terms of such third-party services and ensuring the proper taxes are included in the price listed, and are charged, collected and paid accordingly. We recommend seeking professional help to ensure you handle your local, provincial, federal, municipal and any other taxes correctly. Pairables Marketplace Ltd. only charges applicable taxes on User Fees. Please review Taxes in the Terms for further information.
      </p>
      <h2><strong>1.3 Booking and Posting a Pairable</strong></h2>

      <h4><strong><em>Posting</em></strong></h4>

      <p>
      Pairers who seek to advertise their Pairable on the Platform can create and publish a Listing. Pairables Marketplace Ltd. may inspect, verify, request changes, and remove any Listing at any time.
      </p>
      <h4><strong><em>Booking and Communicating</em></strong></h4>

      <p>
      Users seeking to Book a Pairable <strong>(“Clients”)</strong>, can make a <strong>“Booking Request” </strong>to the Pairer through the Platform and can communicate with each other on the Platform or through other means provided by the Pairer and Client.
      </p>
      <p>
      As We are an advertising platform and do not own any of the Pairables listed on the Platform unless otherwise stated, We will not act on behalf of the Pairer or Client aside from what is set forth in the Terms.
      </p>
      <h4><strong><em>Confirmation, Charges and Payout</em></strong></h4>

      <p>
      The Pairer has up to 24 hours to Confirm the Booking Request unless the Pairer allows for instant booking through their integrated booking system, otherwise the Booking Request is cancelled, and the Client will not be charged. Clients will not be charged until the Booking Request is confirmed by the Pairer <strong>(“Booking Confirmation”)</strong>.
      </p>
      <h4><strong><em>Cancellations</em></strong></h4>

      <p>
      Clients can cancel the Booking through the Platform for a refund in accordance to the Pairer’s selected Cancellation Policy (please see “Refunds & Cancelation policies” for more details). Any further cancellation outside of the selected Cancellation Policy displayed on the Listing Page when booking is not eligible for a refund through the Platform and any further disputes are dealt with directly between the Pairer and Client.
      </p>
      <h2><strong>1.4 Disputes & Issues between Members</strong></h2>

      <p>
      If there is a dispute or issue between Members, and Pairables Marketplace Ltd. is notified before the Pairer receives their Payout, the dispute or issue will be resolved by following these Terms. If the dispute or issue is unable to be resolved by these Terms, then Pairables Marketplace Ltd. has the right to determine, in its sole discretion, a solution to the issue such as but not limited to requiring the Pairer to provide a refund, not requiring the Pairer to provide a refund, requiring the booking to be rescheduled, requiring the Client to pay for any damages, or can do nothing at all.
      </p>
      <h2><strong>1.5 Separate agreements</strong></h2>

      <p>
      All Users agree that some Pairers may require separate agreements to complete a Booking such as but not limited to liability waivers. Any such separate agreements or waivers relating to the Booking are under the sole responsibility and liability of the participating Members and Pairables Marketplace Ltd. will have no part in them. It is the Pairers responsibility to list and describe all required additional documents, waivers, agreements, costs, or charges on the Listing Page.
      </p>
      <h2><strong>1.6 Insurance and Third Party Websites</strong></h2>

      <p>
      Our Platform may link you to other sites (such as a Pairer’s linked booking system or insurance providers) on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other parties. These websites may contain information or material that some people may find inappropriate or offensive. These other websites and parties are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the Website or party by us, or any warranty of any kind, either express or implied.
      </p>
      <p>
      Users agree that Pairables Marketplace Ltd. has and will have no liability to the Pairer, Client, or any third party in the event of any damage, loss, and/or theft of a Pairable listed on or booked through the Platform. Pairables Marketplace Ltd. is not a party to any contract for insurance facilitated through any link to a third-party insurance provider nor acting on behalf of any insurer. If you choose to purchase insurance for your Pairable, you are entering into a contract directly with the third-party insurance provider, not Pairables Marketplace Ltd.
      </p>
      <h2><strong>User’s Content</strong></h2>

      <h2><strong>2.1 Creating an Account</strong></h2>

      <p>
      In order to use the Platform as a Pairer or as a Client, you must first register for an account <strong>(“Account”) </strong>to become a Member.
      </p>
      <h4><strong><em>Registration Data</em></strong></h4>

      <p>
      Pairables Marketplace Ltd. requires Users to provide information to create an Account and become a Member. All personal information will be collected, used and disclosed in accordance with Our Privacy Policy. What you see on other Member’s profiles and Listings is what other Users can see about you. Certain information from you is required to run the Platform properly. Please read Our Privacy Policy for further information.
      </p>
      <h4><strong><em>Eligibility</em></strong></h4>

      <p>
      To List a Pairable on the Platform, You must be at least 18 years of age and the Pairable must be located in British-Columbia, Canada and follow all Local, Municipal, Provincial and Federal Laws and Regulations in the Listing’s location or the location that the Pairable is being used in.
      </p>
      <h4><strong><em>Billing Information</em></strong></h4>

      <p>
      Members are required to provide valid and acceptable payment information which will be provided to Our third-party payment processor Stripe and used for the payment of fees and payouts. Members must inform Pairables Marketplace Ltd. and Stripe if their Billing Information changes.
      </p>
      <h2><strong>2.2 Posting Procedures (Posting a Pairable)</strong></h2>

      <h4><strong><em>Register</em></strong></h4>

      <p>
      Users will be required to register for a Members account and get verified by Stripe before their Listings will be published.
      </p>
      <h4><strong><em>Listing Information</em></strong></h4>

      <p>
      Pairer’s are required to provide true, accurate and complete Listing Information to complete a Listing. The required Listing Information varies from category to category.
      </p>
      <h4><strong><em>Approval Process</em></strong></h4>

      <p>
      Once a Member provides all the required Listing Information, Our Team may review the Listing and confirm that it follows the Terms. This may take up to 72 hours.
      </p>
      <h4><strong><em>Booking Requests</em></strong></h4>

      <p>
      As a Pairer, you will have up to 24 hours to approve a Booking Request.
      </p>
      <h4><strong><em>Payout</em></strong></h4>

      <p>
      After a Booking is successfully complete and as long as there are no disputes between Members, Pairables Marketplace Ltd. will deduct the Service Fee and applicable taxes and you will receive your Payout within 7 business days of the Booking Start Date.
      </p>
      <h4><strong><em>Closing and Deleting a Listing</em></strong></h4>

      <p>
      Closing a Listing: To Close a listing (hide from other Users), simply go to “Your Listings” page, click the Options button on the top right corner of the Listing you would like to Close and click “Close”
      </p>
      <p>
      Deleting a Listing: To Delete a Listing, please contact the Pairables Marketplace Ltd. Team at <a href = "mailto: info@pairables.ca">info@pairables.ca</a>.
      </p>
      <h2><strong>2.3 Booking Procedures (Booking a Pairable)</strong></h2>

      <h4><strong><em>Browse the Platform for a Pairable</em></strong></h4>

      <p>
      Browse our Platform until you find what you want. Feel free to message the Pairer through the Platform at anytime throughout the booking process.
      </p>
      <h4><strong><em>Request to book</em></strong></h4>

      <p>
      Request to book the Pairable from the Pairer by providing a booking<strong> “Start Date”,</strong> your <strong>“Preferred Start Time”, </strong>a booking<strong> “End Date”</strong>, the <strong>“Duration of Rental” </strong>if booking hourly,<strong> </strong>and any further information that may be required by the Pairer.
      </p>
      <h4><strong><em>Booking Confirmation</em></strong></h4>

      <p>
      After a Client has made a Booking Request for a Listing, the Pairer has up to 24 hours to confirm the Booking Request.
      </p>
      <h4><strong><em>When you get charged</em></strong></h4>

      <p>
      Clients will be charged the Total Price once the Booking Request is confirmed by the Pairer <strong>(“Booking Confirmation”)</strong>. The Total Price includes all applicable costs, Pairables Fee, transaction fees and taxes as seen at checkout. If the Pairer requires any further deposits, payments, charges, fees, or taxes, it must be arranged and confirmed by the Client before the Booking Confirmation or must be displayed on the Listing before the Booking Confirmation.
      </p>
      <h4><strong><em>Return</em></strong></h4>

      <p>
      Clients are required to return the Pairable at the agreed upon Location, Time, and Date as confirmed in the Booking Confirmation or otherwise coordinated and confirmed by the Pairer and the Client.
      </p>
      <h4><strong><em>Cancellations</em></strong></h4>

      <p>
      Clients will always have at least 48 hours after the booking was confirmed by the Pairer to cancel a booking for a refund directly through the Platform unless booked within 48 hours of the booking start date pr booked through a Pairer’s established booking system. For more information on Cancelation Policies please review “<em>Refunds & Cancellation Policies”</em> and ensure you read the cancellation policy displayed on the listing page.
      </p>
      <h4><strong><em>Responsibility</em></strong></h4>

      <p>
      If you are renting a Pairable, you are responsible for ensuring that you have the sufficient skill required to operate said Pairable and you are legally authorized to use and operate said Pairable in the location in which you are Renting or using the Pairable. You will not allow any other person(s) to use or operate the Pairable unless they are approved by the Pairer first.
      </p>
      <h4><strong><em>Intent</em></strong></h4>

      <p>
      As a renter of another person’s movable property, you must adhere to the following Terms of Service (TOS). Should these terms not be respected, the renter will be held responsible for the costs associated with the loss or damage to the rented movable property.
      </p>
      <p>
      You, as the renter, will be responsible for the costs associated with the loss or damage to the rented movable property if the loss or damage was caused by:
      </p>

      <ol>

      <li>corrosion, rust, rodents, insects, vermin, dampness of atmosphere, staining or freezing;</li>

      <li>artificially generated electrical currents to electrical appliances or devices (including wiring);</li>

      <li>attempting to fix, adjust or service the rented movable property deemed to be damaged;</li>

      <li>neglect to use all reasonable means to save and preserve the rented movable property at and after any disaster, or when you have notice of an impending disaster;</li>

      <li>a criminal or willful act or omission;</li>

      <li>any act committed or conspired to commit with the intent to cause loss or damage to the rented movable property;</li>

      <li>any actual or alleged spill, discharge, emission, dispersal, seepage, leakage, migration, release or escape of pollutants;</li>

      <li>seizure or confiscation for breach of any law or by order of any public authority;</li>

      </ol>

      <h2><strong>2.4 Pickup and Delivery</strong></h2>

      <h4><strong><em>Pickup</em></strong></h4>

      <p>
      Pairers and Clients are required to meet at the agreed upon pickup or delivery location, time, and date as confirmed in the Booking Confirmation or otherwise coordinated and confirmed by the Pairer and the Client.
      </p>
      <h4><strong><em>Responsibility</em></strong></h4>

      <p>
      Clients are fully responsible and liable for the Pairable once the booking begins and are fully responsible until the Pairable is returned to the Pairer.
      </p>
      <p>
      Clients may be required by the Pairer to cover the costs of damages or replacement of any damages or loss of a Pairable during the booking. Such costs may be deducted from the required Damage Deposit by the Pairer.
      </p>
      <h4><strong><em>Return</em></strong></h4>

      <p>
      Clients are required to return the Pairable at the agreed upon Location, Time, and Date as confirmed in the Booking Confirmation or otherwise coordinated and confirmed by the Pairer and the Client.
      </p>
      <h2><strong>2.5 Conditions of Use</strong></h2>

      <p>
      As a User and as a Member, you must abide by the following conditions (along with all other conditions and terms set forth in the Terms):
      </p>

      <ol>

      <li>You are at least 18 years of age</li>

      <li>You use the Platform according to the Terms and all applicable local, municipal, provincial and federal laws and regulations</li>

      <li>You provide complete and accurate information when registering for an account and listing a Pairable and maintain accurate information on the Platform</li>

      <li>You agree and understand that Pairables Marketplace Ltd. may revoke and/or cancel your access if you fail to abide by the Terms</li>

      <li>You possess the legal authority to create a binding legal obligation.</li>

      <li>The Pairer is responsible for setting a price. The Pairer may not request that the Client pays a higher price than in the booking price at checkout unless pre-approved by the Client before the Booking Request is confirmed by the Pairer.</li>

      <li>You will not interfere with or damage the Platform</li>

      <li>Any terms and conditions that are included in your Listing must not conflict with the Terms.</li>

      <li>You must not create more than two Accounts on the Platform for yourself or on behalf of a third-party</li>

      <li>Pairers must not intentionally approve a booking request for a Listing if that Listing is already confirmed and booked for that Date and Time through the Platform or by other means.</li>

      <li>You must not solicit Pairers or Clients to join a third-party service that may or may not be a competitor to Pairables Marketplace Ltd.</li>

      <li>Use of any Pairables or Host Services booked through the Platform is solely at your own risk</li>

      <li>You will not spam the Platform or any Members of the Platform</li>

      </ol>

      <h2><strong>2.6 User Fees</strong></h2>

      <p>
      There are no fees required to become a member of the Platform. Pairables Marketplace Ltd. charges fees on a per-transaction basis. The <strong>“Pairables Fee” </strong>is paid by the Client when a Booking is confirmed and the <strong>“Service Fee” </strong>is paid by the Pairer by being deducted from the Pairer’s Payout. Such fees are referred to as <strong>“User Fees”</strong>
      </p>
      <h4><strong><em>Pairables Fee</em></strong></h4>

      <p>
      Clients agree to pay a Pairables Fee of 2% plus applicable taxes. The Pairables Fee helps cover some of Our transaction costs. Clients agree to pay a minimum $1.00 Pairables Fee per confirmed Booking Request which includes GST.
      </p>
      <h4><strong><em>Service Fee</em></strong></h4>

      <p>
      Pairers agree to pay a Service Fee (shown as Pairables Fee on transaction records) of 5% plus GST. Pairers agree to pay a minimum $2.00 Service Fee per confirmed Booking Request which includes GST.
      </p>
      <h2><strong>2.7 Taxes</strong></h2>

      <p>
      When posting a Pairable, Pairers must include any required local, municipal, provincial and federal taxes in the Listing Price, which will be collected from the Client at the time of the Booking Confirmation and paid to the Pairer upon Payout. It is the Pairers obligation to seek council from an attorney or a certified accountant to ensure the proper taxes are included in the Price and are collected. Members agree to indemnify and hold harmless Pairables Marketplace Ltd. for any taxes or for anything relating to taxes.
      </p>
      <h2><strong>2.8 Refunds & Cancellation Policies</strong></h2>

      <h4><strong><em>Refunds</em></strong></h4>

      <p>
      Refunds may be offered by Pairables Marketplace Ltd. in accordance to the Cancellation Policy selected by the Pairer. Clients must review the Pairer’s selected Cancellation Policy at the time of the Booking Request. Any further cancellation terms made by the Pairer that are not a part of Pairables’ Cancellation Policy are dealt with solely between the Pairer and the Client. Members agree to hold Pairables Marketplace Ltd. unaccountable for any breaches of cancellation terms that go beyond Our Cancellation Policies.
      </p>
      <h4><strong><em>How to Cancel</em></strong></h4>

      <p>
      To Cancel a booking request within the timeframe of the selected Cancellation Policy by the Pairer, simply go to your Messages, click on the booking reservation you wish to cancel, and click on the “Cancel” button.
      </p>
      <h4><strong><em>Cancellation Policies</em></strong></h4>

      <p>
      Pairables Marketplace Ltd. offers 4 different cancellation policies on the Platform. All policies allow for the Client to have at least 48 hours after the Booking Confirmation to cancel and receive a full refund less any applicable Fees unless the booking confirmation is completed 48 hours or less before the booking start date.
      </p>

      <ol>

      <li>Easy going<br/>Full refund up to 48 hours before the booking start date and time. No refund within 48 hours of the booking start date and time.<br/>If the Client sends a cancellation request at least 48 hours before the booking start date and time, they are entitled to a full refund of the amount paid less any applicable fees.</li>

      <li>Flexible<br/>Full refund up to 7 days before the booking start date and time.  No refund within 7 days of the booking start date and time.<br/>If the Client sends a cancellation request at least 7 days before the booking start date and time, they are entitled to a full refund of the amount paid less any applicable fees.</li>

      <li>Moderate<br/>Full refund up to 30 days before the booking start date and time. No refund within 30 days of the booking start date and time.<br/>If the Client sends a cancellation request at least 30 days before the booking start date and time, they are entitled to a full refund of the amount paid less any applicable fees.</li>

      <li>Strict<br/>Full refund if cancelled within 48 hrs after the Pairer confirms the Renter’s booking request. No refund if cancelled more than 48 hours after the Pairer confirms the Renters booking request.<br/>If the Client sends a cancellation request within 48 hours after the Pairer confirms the Booking Request, they are entitled to a full refund of the amount paid less any applicable fees. There is no refund if the Client cancels 48 hours or less before the booking start date.</li>

      </ol>

      <h2><strong>2.9 Damage Deposits</strong></h2>

      <p>
      Damage deposits are optional. If you wish to require a damage deposit, it is your sole responsibility to collect, hold, and return the damage deposit.
      </p>
      <p>
      If the Pairer requires a Damage Deposit, the amount must be included on the Listing and the Pairer must provide the Client with the preferred payment methods for the deposit. Pairables Marketplace Ltd. does not handle Damage Deposits and We simply provide the means to display what Damage Deposit is required by the Pairer and how the Pairer would like to collect the Damage Deposit.
      </p>
      <h2><strong>2.10 Payouts</strong></h2>

      <p>
      Pairables Marketplace Ltd. will release the amount owed to the Pairer, the <strong>“Payout” </strong>(The Total Price less Fees and applicable taxes) as soon as the Booking Start Date is marked as Complete. The Payout may take several business days to be deposited into the Pairer’s provided bank account.
      </p>
      <p>
      If the Pairer does not follow the Terms, Pairables Marketplace Ltd. holds the right, at its sole discretion to return the amount charged to the Client (less any applicable fees) to the Client.
      </p>
      <h2><strong>2.11 Late Fees</strong></h2>

      <p>
      In the event that a Pairable is not returned to the Pairer at the agreed upon Location and time, the Pairer holds the right to charge the Client a <strong>“Late fee”</strong> as determined by the Pairer.
      </p>
      <h2><strong>2.12 Reviews & Ratings</strong></h2>

      <p>
      Reviews and Rating are public to other Users. Pairables Marketplace Ltd. holds the right to remove any reviews that we deem to be inaccurate, rude, false, irrelevant, misleading, profound or a scam.
      </p>
      <h4><strong><em>Ratings</em></strong></h4>

      <p>
      Members can rank other Members about their experience with them based on a star rating from 1 Star (being poor) to 5 Stars (being excellent).
      </p>
      <h4><strong><em>Reviews</em></strong></h4>

      <p>
      Members can leave a review that will be made public to all Users.
      </p>
      <h2><strong>Disclaimer of Warranty and Limitation of Liability</strong></h2>

      <p>
      The Platform is provided "AS IS." The Company, Team, its suppliers, officers, directors, employees, and agents exclude and disclaim all representations and warranties, express or implied, related to this Platform or in connection with the services. You exclude Pairables Marketplace Ltd. from all liability for damages related to or arising out of the use of this Platform.
      </p>
      <p>
      While every effort is made to ensure that the content of the Platform is accurate, the Platform is provided “as is” and Pairables Marketplace Ltd. makes no representations or warranties in relation to the accuracy or completeness of the information found on it. While the content of this site is provided in good faith, we do not warrant that the information will be kept up to date, be true and not misleading, or that this site will always (or ever) be available for use.
      </p>
      <p>
      We do not warrant that the servers that make this Platform available will be error, virus or bug free and you accept that it is your responsibility to make adequate provision for protection against such threats. We recommend scanning any files before downloading.
      </p>
      <p>
      Nothing on this Platform should be taken to constitute professional advice or a formal recommendation and we exclude all representations and warranties relating to the content and use of this site.
      </p>
      <p>
      In no event will Pairables Marketplace Ltd. be liable for any incidental, indirect, consequential or special damages of any kind, or any damages whatsoever, including, without limitation, those resulting from loss of profit, loss of contracts, goodwill, data, information, income, anticipated savings or business relationships, whether or not advised of the possibility of such damage, arising out of or in connection with the use of this Platform or any linked websites, services, and third-parties.
      </p>
      <h2><strong>Governing Law</strong></h2>

      <p>
      These Terms and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of British-Columbia, Canada without giving effect to any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Platform shall be instituted exclusively in the Federal courts of Canada
      </p>
      <h2><strong>Licence To Use This Website</strong></h2>

      <p>
      By using this Platform you agree to the exclusions and limitations of liability stated above and accept them as reasonable. Do not use this website if you do not agree that they are reasonable. If any of the points in the Terms are found to be unenforceable under applicable law, that will have no bearing on the enforceability of the rest of the disclaimer notice.
      </p>
      <p>
      Material on this website, including text and images, is protected by copyright law and is copyright to Pairables Marketplace Ltd. unless credited otherwise. It may not be copied, reproduced, republished, downloaded, posted, broadcast or transmitted in any way except for your own personal, non-commercial use. Unauthorized use of any materials contained on this Platform or within the Service may violate copyright laws, trademark laws, the laws of privacy and publicity, and/or other regulations and statutes. If you believe that any of the materials infringe on any third party's rights, please contact Pairables Marketplace Ltd. immediately at the address provided below.
      </p>
      <p>
      Prior written consent of the copyright holder must be obtained for any other use of material.
      </p>
      <p>
      No part of this site may be distributed or copied for any commercial purpose or financial gain.
      </p>
      <p>
      All intellectual property rights in relation to this website are reserved and owned by Pairables Marketplace Ltd.
      </p>
      <h2><strong>Contact Us</strong></h2>

      <p>
      If you have any questions about this Agreement or Our Privacy Policy, You can contact us:
      </p>
      <p>
      By email: <a href = "mailto: info@pairables.ca">info@pairables.ca</a>
      </p>
      <p>
      By visiting this page on our website: <a href="http://www.pairables.ca/">www.pairables.ca</a>
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
