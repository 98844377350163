import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import * as validators from '../../util/validators';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldRadioButton, FieldTextInput } from '../../components';

import css from './EditListingCancellationPolicyForm.css';

export const EditListingCancellationPolicyFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        isPublished,
        isVerified,
        isFareharbor,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const firstPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.firstPolicyTitle" />;
      const firstPolicyNote = <FormattedMessage id="EditListingPoliciesForm.firstPolicyNote" />;
      const firstPolicyDescription = (
        <FormattedMessage id="EditListingPoliciesForm.firstPolicyDescription" />
      );
      const secondPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.secondPolicyTitle" />;
      const secondPolicyNote = <FormattedMessage id="EditListingPoliciesForm.secondPolicyNote" />;
      const secondPolicyDescription = (
        <FormattedMessage id="EditListingPoliciesForm.secondPolicyDescription" />
      );
      const thirdPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.thirdPolicyTitle" />;
      const thirdPolicyNote = <FormattedMessage id="EditListingPoliciesForm.thirdPolicyNote" />;
      const thirdPolicyDescription = (
        <FormattedMessage id="EditListingPoliciesForm.thirdPolicyDescription" />
      );
      const fourthPolicyTitle = <FormattedMessage id="EditListingPoliciesForm.fourthPolicyTitle" />;
      const fourthPolicyNote = <FormattedMessage id="EditListingPoliciesForm.fourthPolicyNote" />;
      const fourthPolicyDescription = (
        <FormattedMessage id="EditListingPoliciesForm.fourthPolicyDescription" />
      );

      const policyLabelMessage = intl.formatMessage({
        id: 'EditListingPolicyForm.policyLabel',
      });
      const policyPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPolicyForm.policyPlaceholder',
      });
      const policyRequiredMessage = validators.required(
        intl.formatMessage({
          id: 'EditListingPolicyForm.policyRequired',
        })
      );

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {isFareharbor ? (
            <FieldTextInput
              id="cancellationPolicy"
              name="cancellationPolicy"
              className={css.policy}
              type="textarea"
              placeholder={policyPlaceholderMessage}
              validate={policyRequiredMessage}
            />
          ) : (
            <div>
              <FieldRadioButton
                name="cancellationPolicy"
                label={firstPolicyTitle}
                note={firstPolicyNote}
                description={firstPolicyDescription}
                value="1"
                id="1"
              />

              <FieldRadioButton
                name="cancellationPolicy"
                label={secondPolicyTitle}
                note={secondPolicyNote}
                description={secondPolicyDescription}
                value="2"
                id="2"
              />

              <FieldRadioButton
                name="cancellationPolicy"
                label={thirdPolicyTitle}
                note={thirdPolicyNote}
                description={thirdPolicyDescription}
                value="3"
                id="3"
              />

              <FieldRadioButton
                name="cancellationPolicy"
                label={fourthPolicyTitle}
                note={fourthPolicyNote}
                description={fourthPolicyDescription}
                value="4"
                id="4"
              />
            </div>
          )}

          {isVerified ? (
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          ) : (
            <div className={css.frame}>
              <div className={css.partOne}>
                <h4 className={css.details}>Add payout details and verify your account</h4>
                <h5 className={css.description}>
                  In order to publish your listing and start receiving payments, you will first need
                  to add your banking details using our third-party payment provider Stripe.
                </h5>
              </div>
              <div className={css.partTwo}>
                <Button
                  className={css.submitButtons}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </div>
          )}
        </Form>
      );
    }}
  />
);

EditListingCancellationPolicyFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingCancellationPolicyFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingCancellationPolicyFormComponent);
