import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { SectionHeroSearchForm } from '../../forms';
import { Button, NamedLink } from '../../components';

import css from './SectionHeroDouble.css';
import logo from './hero-logo.svg';

const SectionHeroDouble = props => {
  const {
    rootClassName,
    className,
    history,
    isMobileLayout,
    handleScrollToFeaturedTour,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h2 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHeroDouble.title" />
        </h2>
        <div className={css.heroButtons}>
          <Button className={css.heroButton} onClick={handleScrollToFeaturedTour}>
            <FormattedMessage id="SectionHero.featuredTourButton" />
          </Button>
        </div>
      </div>
      {isMobileLayout ? null : <div className={css.scrollTarget}></div>}
    </div>
  );
};

SectionHeroDouble.defaultProps = { rootClassName: null, className: null };

SectionHeroDouble.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHeroDouble;
