import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Logo,
  Footer,
  ExternalLink,
} from '../../components';

import css from './HowItWorksPage.css';

import coverImage from './hiw-header-image.jpg';

const HowItWorksPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="How It Works"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Learn how Pairables works',
        name: 'How It Works page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.headerWrapper}>
            <div className={css.coverImage}>
              <img src={coverImage} />
            </div>
            <div className={css.headerContent}>
              <Logo format="white" />
              <h2>
                <FormattedMessage id="Site.tagline" />
              </h2>
            </div>
          </div>
          <div className={css.contentWrapper}>
						<div className={css.column}>
							<div className={css.pageTitle}>
								<h2 className={css.title}>
									<FormattedMessage id="HowItWorksPage.rentTitle" />
								</h2>
							</div>
							<div className={css.contentMain}>
								<ol>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.rentStepOne" />
									</li>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.rentStepTwo" />
									</li>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.rentStepThree" />
									</li>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.rentStepFour" />
									</li>
								</ol>
								<p className={css.small}>
									<FormattedMessage id="HowItWorksPage.rentNote" />
								</p>
							</div>
						</div>
						<div className={css.column}>
							<div className={css.pageTitle}>
								<h2 className={css.title}>
									<FormattedMessage id="HowItWorksPage.postTitle" />
								</h2>
							</div>
							<div className={css.contentMain}>
								<ol>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.postStepOne" />
									</li>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.postStepTwo" />
									</li>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.postStepThree" />
									</li>
									<li>
										<FormattedHTMLMessage id="HowItWorksPage.postStepFour" />
									</li>
								</ol>
							</div>
              <div className={css.frame}>
                <p className={css.question}>
                  Rent out your stuff with confidence!
                </p>

                <p className={css.message}>
                  We've partnered with Duuo to provide you with optional on-demand damage protection insurance. Simply sign up now and let Duuo know when you get bookings!
                </p>

                <ExternalLink href="https://duuo.ca/rent-my-stuff-insurance/?utm_medium=website&utm_source=pairable&utm_campaign=rmspartnership" className={css.link}>
                  Sign up & get covered!
                </ExternalLink>
              </div>
						</div>
					</div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HowItWorksPage;
