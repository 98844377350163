import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const BuyActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    annulByCustomerInProgress,
    cancelByCustomerInProgress,
    declineByCustomerInProgress,
    annulBuyError,
    cancelBuyError,
    declineBuyError,
    headingState,
    onAnnulBuy,
    onCancelBuy,
    onDeclineBuy,
    cancel,
    cancellation,
  } = props;

  const buttonsDisabled = cancelByCustomerInProgress || declineByCustomerInProgress;

  const cancelErrorMessage = cancelBuyError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelBuyFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineBuyError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineBuyFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {cancelErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {headingState === 'requested' ? (
          <div>
            <PrimaryButton
              inProgress={declineByCustomerInProgress}
              disabled={buttonsDisabled}
              onClick={onDeclineBuy}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </PrimaryButton>
            <span className={css.policyInfo}>
              <FormattedMessage id="TransactionPanel.declineButtonTooltip" />
            </span>
          </div>
        ) : null}
        {headingState === 'accepted' ? (
          <div>
            <PrimaryButton
              inProgress={cancelByCustomerInProgress}
              disabled={buttonsDisabled}
              onClick={cancel ? onCancelBuy : onAnnulBuy}
            >
              <FormattedMessage id="TransactionPanel.cancelButton" />
            </PrimaryButton>
            <span className={css.policyInfo}>
              {cancel ? (
                <FormattedMessage
                  id="TransactionPanel.cancelButtonTooltip"
                  values={{ cancellation }}
                />
              ) : (
                <FormattedMessage
                  id="TransactionPanel.cancelOutButtonTooltip"
                  values={{ cancellation }}
                />
              )}
              <br />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default BuyActionButtonsMaybe;
