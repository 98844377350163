import React from 'react';
import { FieldSelect } from '../../components';
import includes from 'lodash/includes';
import { Field } from 'react-final-form';
import { Creatable } from 'react-select';

import css from './EditListingFeaturesForm.css';

const CustomAmenityMultiselectFieldMaybe = props => {
  if (typeof window === 'undefined') {
    return null;
  }

  const { default: Select, components } = require('react-select'); // eslint-disable-line global-require
  const { name, id, amenityOptions, intl } = props;
  const amenityLabel =
    'List any features or amenities that make your Pairable stand out! Type in a feature and click the enter key to add it to your listing.';

  return (
    <div>
      <p>{amenityLabel}</p>
      <Field name={name} id={id} label={amenityLabel}>
        {({ input, meta }) => {
          return (
            <Creatable
              closeMenuOnSelect={false}
              defaultValue={meta.initial}
              isMulti
              onChange={input.onChange}
              options={[]}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default CustomAmenityMultiselectFieldMaybe;
