import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      width="46"
      height="46"
      xmlns="http://www.w3.org/2000/svg"
      clipRule="evenodd"
    >
      <g>
        <circle r="23" cy="23" cx="23" />
        <path
          fill="#fff"
          d="M24.268 14.524c-.82-.16-1.844-.583-2.764-1.605-1.905-2.114-1.495-4.716-1.482-4.797V8.12s2.39-.504 4.325 1.644c1.144 1.27 1.453 2.716 1.514 3.686.293-.825.79-1.524 1.447-1.93.643-.397 1.445-.54 2.394-.23a.805.894 0 01-.455 1.715c-.457-.15-.846-.115-1.156.076-.508.315-.793.979-.888 1.675a.818.908 0 01-.045.185c2.187 1.126 3.306 3.52 2.938 5.747 0 0-.143.491-.084 1.095.046.467.261 1.015.454 1.368 1.546 2.813 1.362 5.319 1.362 5.319a7.68 8.524 0 01-.243 2.654c-1.394 5.771-6.17 7.82-10.638 6.348l-.052-.018c-4.56-1.183-7.805-5.621-6.406-11.415a7.673 8.517 0 01.984-2.433s.97-2.273 3.575-3.851c.327-.198.76-.554 1.01-.933.323-.49.42-.994.42-.994a4.82 4.82 0 013.79-3.304z"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
