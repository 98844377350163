import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingLocationForm.css';

const CustomRegionSelectFieldMaybe = props => {
  const { name, id, regions, intl } = props;
  const regionLabel = intl.formatMessage({
    id: 'EditListingLocationForm.regionLabel',
  });
  const regionPlaceholder = intl.formatMessage({
    id: 'EditListingLocationForm.regionPlaceholder',
  });
  const regionRequired = required(
    intl.formatMessage({
      id: 'EditListingLocationForm.regionRequired',
    })
  );
  return regions ? (
    <FieldSelect
      className={css.region}
      name={name}
      id={id}
      label={regionLabel}
      validate={regionRequired}
    >
      <option disabled value="">
        {regionPlaceholder}
      </option>
      {regions.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomRegionSelectFieldMaybe;
