import React, { Component } from 'react';
import Slider from 'react-slick';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './CategoryCarousel.css';

class CategoryCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.beforeChange = this.beforeChange.bind(this);
  }

  state = {
    currentSlide: 0,
  };

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  beforeChange(oldIndex, newIndex) {
    this.setState({ currentSlide: newIndex });
  }

  render() {
    const { title, items } = this.props;
    const { currentSlide } = this.state;

    const Arrow = ({ direction }) => {
      return (
        <svg width="11" height="18" viewBox="0 0 11 18" xmlns="http://www.w3.org/2000/svg">
          {direction == 'left' ? (
            <path
              d="M2.828,8.707l7.293,-7.293l-1.414,-1.414l-8.707,8.707l8.707,8.707l1.414,-1.414l-7.293,-7.293Z"
              fill="#36a251"
            />
          ) : direction == 'right' ? (
            <path
              d="M7.293,8.707l-7.293,-7.293l1.414,-1.414l8.707,8.707l-8.707,8.707l-1.414,-1.414l7.293,-7.293Z"
              fill="#36a251"
            />
          ) : null}
        </svg>
      );
    };

    const prevArrowClass =
      currentSlide === 0 ? classNames(css.arrowLeft, css.arrowInactive) : css.arrowLeft;

    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          },
        },
        {
          breakpoint: 549,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
      ],
    };

    return (
      <div className={css.root}>
        <div className={css.header}>
          <div className={css.title}>{title}</div>
          <nav className={css.carouselNavigation}>
            <button
              className={prevArrowClass}
              onClick={this.previous}
              disabled={currentSlide === 0}
            >
              <Arrow direction="left" />
            </button>
            <button className={css.arrowRight} onClick={this.next}>
              <Arrow direction="right" />
            </button>
          </nav>
        </div>
        <div className={css.carousel}>
          <Slider ref={c => (this.slider = c)} beforeChange={this.beforeChange} {...settings}>
            {items.map(i => (
              <div className={css.carouselItem} key={i.key}>
                <NamedLink name="SearchPage" to={{ search: i.link }}>
                  <div className={css.carouselItemImage}>
                    <img src={i.image} alt={i.title} />
                  </div>
                  <div className={css.carouselItemTitle}>{i.title}</div>
                </NamedLink>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default CategoryCarousel;
